import {
  EmailSchema,
  FieldRequired,
  IDCardSchema,
  NPWPSchema,
  PasswordSchema,
  PhoneNumberSchema,
  RegularSchema,
  AmountSchema,
  PassportSchema,
  URLSchema,
  AddressSchema,
  AlphanumericWithDashSpaceSchema,
  OnlyNumberSchema,
  SwiftNumberSchema,
  CitySchema,
  PostCodeSchema,
} from "./validationField";

const handleChangeTextfield = (
  t,
  event,
  name,
  setValidationError,
  onChange,
  label,
  isValidate
) => {
  if (!isValidate) {
    onChange(event);
    return;
  }

  const { value } = event.target;

  const schemas = {
    swift: SwiftNumberSchema,
    password: PasswordSchema,
    email: EmailSchema,
    phone: PhoneNumberSchema,
    npwp: NPWPSchema,
    ktp: IDCardSchema,
    idcard: IDCardSchema,
    passport: PassportSchema,
    url: URLSchema,
    default: FieldRequired,
    city: CitySchema,
    name: RegularSchema,
    title: AlphanumericWithDashSpaceSchema,
    type: RegularSchema,
    subject: RegularSchema,
    amount: AmountSchema,
    address: AddressSchema,
    note: AddressSchema,
    time: OnlyNumberSchema,
    postcode: PostCodeSchema,
    number: OnlyNumberSchema,
  };

  const schemaKey = Object.keys(schemas).find((key) =>
    name.toLowerCase().includes(key)
  );
  const schema = schemas[schemaKey] || schemas.default;

  let result = null;
  if (schemaKey === "amount") {
    result = schema(t, label).safeParse(value.replace(/\./g, ""));
  } else {
    result = schema(t, label).safeParse(value);
  }
  setValidationError(result.success ? "" : result.error.errors[0].message);

  onChange(event);
};

export default handleChangeTextfield;
