const DeleteIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 23}
    height={heightIcon || 23}
    viewBox="0 0 22 23"
    fill="none"
  >
    <path
      d="M18.5625 4.625H15.125V3.9375C15.125 3.39049 14.9077 2.86589 14.5209 2.47909C14.1341 2.0923 13.6095 1.875 13.0625 1.875H8.9375C8.39049 1.875 7.86589 2.0923 7.47909 2.47909C7.0923 2.86589 6.875 3.39049 6.875 3.9375V4.625H3.4375C3.25516 4.625 3.0803 4.69743 2.95136 4.82636C2.82243 4.9553 2.75 5.13016 2.75 5.3125C2.75 5.49484 2.82243 5.6697 2.95136 5.79864C3.0803 5.92757 3.25516 6 3.4375 6H4.125V18.375C4.125 18.7397 4.26987 19.0894 4.52773 19.3473C4.78559 19.6051 5.13533 19.75 5.5 19.75H16.5C16.8647 19.75 17.2144 19.6051 17.4723 19.3473C17.7301 19.0894 17.875 18.7397 17.875 18.375V6H18.5625C18.7448 6 18.9197 5.92757 19.0486 5.79864C19.1776 5.6697 19.25 5.49484 19.25 5.3125C19.25 5.13016 19.1776 4.9553 19.0486 4.82636C18.9197 4.69743 18.7448 4.625 18.5625 4.625ZM9.625 14.9375C9.625 15.1198 9.55257 15.2947 9.42364 15.4236C9.2947 15.5526 9.11984 15.625 8.9375 15.625C8.75516 15.625 8.5803 15.5526 8.45136 15.4236C8.32243 15.2947 8.25 15.1198 8.25 14.9375V9.4375C8.25 9.25516 8.32243 9.0803 8.45136 8.95136C8.5803 8.82243 8.75516 8.75 8.9375 8.75C9.11984 8.75 9.2947 8.82243 9.42364 8.95136C9.55257 9.0803 9.625 9.25516 9.625 9.4375V14.9375ZM13.75 14.9375C13.75 15.1198 13.6776 15.2947 13.5486 15.4236C13.4197 15.5526 13.2448 15.625 13.0625 15.625C12.8802 15.625 12.7053 15.5526 12.5764 15.4236C12.4474 15.2947 12.375 15.1198 12.375 14.9375V9.4375C12.375 9.25516 12.4474 9.0803 12.5764 8.95136C12.7053 8.82243 12.8802 8.75 13.0625 8.75C13.2448 8.75 13.4197 8.82243 13.5486 8.95136C13.6776 9.0803 13.75 9.25516 13.75 9.4375V14.9375ZM13.75 4.625H8.25V3.9375C8.25 3.75516 8.32243 3.5803 8.45136 3.45136C8.5803 3.32243 8.75516 3.25 8.9375 3.25H13.0625C13.2448 3.25 13.4197 3.32243 13.5486 3.45136C13.6776 3.5803 13.75 3.75516 13.75 3.9375V4.625Z"
      fill="#A0989A"
    />
  </svg>
);

export default DeleteIcon;
