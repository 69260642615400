import RequestHttpGateway from "./RequestHttpGateway";
import headerRequest from "./headerRequest";

const getMerchantVerification = () => {
  const headers = headerRequest();
  return RequestHttpGateway.get("/merchant/verification-status?detail=true", {
    headers,
  });
};

export default getMerchantVerification;
