const CheckBlueIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
    >
      <path
        d="M9.5 1.6875C8.05373 1.6875 6.63993 2.11637 5.4374 2.91988C4.23486 3.72339 3.2976 4.86544 2.74413 6.20163C2.19067 7.53781 2.04586 9.00811 2.32801 10.4266C2.61017 11.8451 3.30661 13.148 4.32928 14.1707C5.35196 15.1934 6.65492 15.8898 8.07341 16.172C9.49189 16.4541 10.9622 16.3093 12.2984 15.7559C13.6346 15.2024 14.7766 14.2651 15.5801 13.0626C16.3836 11.8601 16.8125 10.4463 16.8125 9C16.8105 7.06123 16.0394 5.20246 14.6685 3.83154C13.2975 2.46063 11.4388 1.68955 9.5 1.6875ZM12.7105 7.71047L8.77297 11.648C8.72073 11.7003 8.65869 11.7418 8.59041 11.7701C8.52212 11.7984 8.44892 11.8129 8.375 11.8129C8.30108 11.8129 8.22789 11.7984 8.1596 11.7701C8.09131 11.7418 8.02928 11.7003 7.97703 11.648L6.28953 9.96047C6.18399 9.85492 6.12469 9.71177 6.12469 9.5625C6.12469 9.41323 6.18399 9.27008 6.28953 9.16453C6.39508 9.05898 6.53824 8.99969 6.6875 8.99969C6.83677 8.99969 6.97992 9.05898 7.08547 9.16453L8.375 10.4548L11.9145 6.91453C11.9668 6.86227 12.0288 6.82081 12.0971 6.79253C12.1654 6.76424 12.2386 6.74969 12.3125 6.74969C12.3864 6.74969 12.4596 6.76424 12.5279 6.79253C12.5962 6.82081 12.6582 6.86227 12.7105 6.91453C12.7627 6.96679 12.8042 7.02884 12.8325 7.09712C12.8608 7.1654 12.8753 7.23859 12.8753 7.3125C12.8753 7.38641 12.8608 7.4596 12.8325 7.52788C12.8042 7.59616 12.7627 7.65821 12.7105 7.71047Z"
        fill="#1677FF"
      />
    </svg>
  );
};

export default CheckBlueIcon;
