import classes from "../RegistMerchant.module.css";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import useSnackbar from "../../General/Helper/useSnackbar";
import { CardInformation } from "../../General/Component/CardInformation";
import { useTranslation } from "react-i18next";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "../../General/Icon/ExpandMoreIcon";
import { useEffect, useState } from "react";
import Buttons from "../../General/Component/Buttons";
import ContactSection from "../../General/Component/ContactSection";
import VerificationFailed from "../../General/Component/VerificationFailed";
import LeftArrow from "../../General/Icon/LeftArrow";
import getListChannel from "../Helper/getListChannel";
import { defaultLogoChannel } from "../../General/Icon/DefaultLogoChannel";
import currencyFormat from "../Helper/currencyFormat";
import { LoadingState } from "../../General/Component/LoadingComp";

export function VerificationStatus({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
  errorVerificationData,
  setErrorVerificationData,
  forBtnBack,
  setForBtnBack,
}) {
  /*
    note tahap development:
    1. validasi status successfully
    2. validasi status rejected
    */

  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const [statusProd, setStatusProd] = useState("");
  const [loading, setLoading] = useState(false);
  const [channel, setChannel] = useState({
    cc: [],
    virtualAccount: [],
    eWallet: [],
    otc: [],
  });

  useEffect(() => {
    getListChannel(
      setLoading,
      showSnackbar,
      t,
      strings,
      setChannel,
      setStatusProd,
      setErrorVerificationData
    );
  }, [setErrorVerificationData, showSnackbar, t]);

  const paymentList = (list) => {
    let merchantChannelType = list[0]?.channels[0]?.channel_type;
    if (merchantChannelType === "WALLET") {
      merchantChannelType = t(strings.textWallet);
    } else if (merchantChannelType === "CREDIT") {
      merchantChannelType = t(strings.textCreditCard);
    } else if (merchantChannelType === "VIRTUAL ACCOUNT") {
      merchantChannelType = t(strings.textVA);
    } else if (merchantChannelType === "OTC") {
      merchantChannelType = t(strings.textOTC);
    }
    return (
      <>
        {list.length > 0 ? (
          <>
            <Accordion
              disableGutters
              sx={{
                boxShadow: "none",
                borderBottom: "1px solid #CFDFEC",
              }}
            >
              <AccordionSummary
                sx={{ padding: "0" }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography className={classes.summary_text}>
                  {t(merchantChannelType)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ paddingLeft: "0", paddingRight: "0", paddingBottom: "0" }}
              >
                <Grid container spacing={2}>
                  {[
                    {
                      label: strings.textPaymentMethod,
                      textAlign: "left",
                    },
                    { label: strings.fees, textAlign: "right" },
                  ].map((el) => (
                    <Grid item xs={6} sx={{ textAlign: el.textAlign }}>
                      <Typography className={classes.textRegularDarkGray14}>
                        {t(el.label)}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </AccordionDetails>
              {list.map((el, index) => {
                const accordionDetailsStyle = {
                  paddingLeft: "0",
                  paddingRight: "0",
                  marginBottom: "16px",
                };
                if (index !== list?.length - 1) {
                  accordionDetailsStyle["paddingBottom"] = "0";
                }
                return (
                  <AccordionDetails sx={accordionDetailsStyle}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className={classes.div_img_value}>
                          <img
                            src={
                              el.channel_logo_url !== ""
                                ? el.channel_logo_url
                                : defaultLogoChannel
                            }
                            alt="logo"
                            width="50px"
                          />
                          <div className={classes.div_name_opsi_payment}>
                            <Typography className={classes.value_payment}>
                              {el.channel_name}
                            </Typography>

                            <div className={classes.div_name_opsi_payment}>
                              <Typography
                                className={classes.textPaymentOptions}
                              >
                                {t(strings.textPaymentOption)}
                              </Typography>
                              {el.channels.map((item) => {
                                return (
                                  <>
                                    <div
                                      className={classes.div_opsi_payment_fee}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        sx={{ textAlign: "left" }}
                                      >
                                        <Typography
                                          className={
                                            item.channel_status === "active"
                                              ? classes.textPaymentOptions
                                              : classes.textPaymentOptionsInactive
                                          }
                                        >
                                          {item.channel_mode.toLowerCase()}
                                        </Typography>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={6}
                                        sx={{ textAlign: "right" }}
                                      >
                                        <Typography
                                          className={
                                            item.channel_status === "active"
                                              ? classes.textValueFee
                                              : classes.textValueFeeInactive
                                          }
                                        >
                                          {item.channel_fee_percentage > 0
                                            ? (
                                                item.channel_fee_percentage *
                                                100
                                              ).toFixed(2) + "% + "
                                            : null}
                                          {currencyFormat(item.channel_fee)}
                                        </Typography>
                                      </Grid>
                                    </div>
                                    {item.channel_status === "inactive" ? (
                                      <>
                                        <Grid
                                          container
                                          className={
                                            classes.divLabelAndValueReject
                                          }
                                        >
                                          <Typography
                                            className={
                                              classes.labelErrorChannel
                                            }
                                          >
                                            {t(strings.textRejectionReason)}{" "}
                                          </Typography>
                                          <Typography
                                            className={
                                              classes.valueErrorChannel
                                            }
                                          >
                                            {item.reject_reason
                                              ? item.reject_reason
                                              : "-"}
                                          </Typography>
                                        </Grid>
                                      </>
                                    ) : null}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                );
              })}
            </Accordion>
          </>
        ) : null}
      </>
    );
  };

  const handlePreviousSection = () => {
    // back to web staging
    setActiveStepOutlined((prev) => prev - 2);
    setActiveSectionNumber(0);
    setForBtnBack(true);
  };

  return !loading || statusProd !== "" ? (
    <>
      <>
        <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />
        <div className={classes.divFormStepper}>
          {statusProd === "DONE" ? (
            <>
              <CardInformation
                header={t(strings.verificationSuccess)}
                text={t(strings.registrationSuccess)}
                status={"success"}
              />

              <div style={{ marginTop: "30px" }}>
                <Typography className={classes.textSuperBold18}>
                  {t(strings.supportAndPaymentInfo)}
                </Typography>
                <Typography
                  sx={{ marginTop: "8px" }}
                  className={classes.textRegularDarkGray14}
                >
                  {t(strings.paymentProviderDetails)}
                </Typography>
              </div>

              <div
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                {[
                  paymentList(channel.cc),
                  paymentList(channel.virtualAccount),
                  paymentList(channel.eWallet),
                  paymentList(channel.otc),
                ].map((el, i) => {
                  const style = {
                    cursor: "pointer",
                  };
                  if (i > 0) {
                    style["marginTop"] = "16px";
                  }
                  return <div style={style}>{el}</div>;
                })}
              </div>
            </>
          ) : (
            <VerificationFailed
              t={t}
              reasonReject={errorVerificationData}
              showSnackbar={showSnackbar}
              setActiveSectionNumber={setActiveSectionNumber}
              setActiveStepOutlined={setActiveStepOutlined}
            />
          )}
          <div style={{ width: "100%" }}>
            <ContactSection
              t={t}
              title={t(strings.textHaveAQuestion)}
              subtitle={t(strings.textContactViaWaandEmail)}
            />
          </div>

          <Buttons
            loadingSubmit={false}
            label={t(strings.textPrevious)}
            leftIcon={<LeftArrow />}
            isSolid={false}
            handleDisabledButton={() => null}
            onSubmitData={handlePreviousSection}
          />
        </div>
      </>
    </>
  ) : (
    <>
      <LoadingState />
    </>
  );
}
