import { useTranslation } from "react-i18next";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import useSnackbar from "../../General/Helper/useSnackbar";
import objectBusinessInfo from "../Constant/objectBusinessInfo";
import { Typography } from "@mui/material";
import Textfields from "../../General/Component/Textfields";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import classes from "../RegistMerchant.module.css";
import Buttons from "../../General/Component/Buttons";
import RightIcon from "../../General/Icon/RightIcon";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import AutoCompleteSelect from "../../General/Component/AutocompleteSelect";
import objectBankAccount from "../Constant/objectBankAccount";
import RadioButtons from "../../General/Component/RadioButtons";
import currencyList from "../Constant/currencyList";
import formatNumber from "../../General/Helper/formatNumber";
import onSubmitMerchantInfo from "../Helper/onSubmitMerchantInfo";
import getMerchantDetail from "../Helper/getMerchantDetail";
import LeftArrow from "../../General/Icon/LeftArrow";
import { renderObjectBank } from "../Helper/renderBankInfo";
import {
  isValidAmount,
  isValidName,
  isValidNumber,
  isValidSwift,
} from "../../General/Helper/handleValidation";
import { LoadingState } from "../../General/Component/LoadingComp";
import InfoDataCorrection from "./InfoDataCorrection";
import getBankList from "../../General/Helper/getBankList";
import InfoComponent from "../../General/Component/InfoComponent";
import getCategoryMerchant from "../Helper/getCategoryMerchant";
import getMerchantCriteria from "../Helper/getMerchantCriteria";
import listTax from "../Constant/listTax";

const FormBusinessInfo = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
  errorVerificationData,
  setErrorVerificationData,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const [merchantDetail, setMerchantDetail] = useState(null);
  const [loadingMerchant, setLoadingMerchant] = useState(true);
  const [bankList, setBankList] = useState([]);
  const [industryType, setIndustryType] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]);
  const [formData, setFormData] = useState({
    merchantCriteria: "",
    productName: "",
    marketTargetName: "",
    industryName: "",
    monthlySalesAmount: "",
    companyTaxType: "",
    averageBillAmount: "",
    monthlySalesTurnoverAmount: "",
    bankName: "",
    bankSwiftNumber: "",
    bankBranchName: "",
    bankNumber: "",
    bankAccountName: "",
    currency: "IDR",
    loadingSubmit: false,
  });
  const merchantVerif = useSelector(
    (state) => state.user_data.verificationStatus
  );

  useEffect(() => {
    getMerchantDetail(
      t,
      strings,
      activeSectionNumber,
      activeStepOutlined,
      setMerchantDetail,
      setLoadingMerchant,
      showSnackbar
    );
    getCategoryMerchant(setIndustryType, setLoadingMerchant);
    if (merchantVerif) {
      setErrorVerificationData(merchantVerif?.reason);
    }
    getBankList("", setBankList, setLoadingMerchant);
    getMerchantCriteria(t, strings, setCriteriaList, setLoadingMerchant);
  }, [
    activeSectionNumber,
    activeStepOutlined,
    showSnackbar,
    t,
    merchantVerif,
    setErrorVerificationData,
  ]);

  useEffect(() => {
    if (merchantDetail) {
      const {
        merchantProduct,
        targetMarket,
        taxType,
        industryCode,
        currentMonthlySales,
        estimatedAverageBillingAccount,
        estimatedMonthlySalesTurnover,
        merchantBank,
        criteriaCode,
      } = merchantDetail;

      setFormData((prev) => ({
        ...prev,
        productName: merchantProduct || "",
        marketTargetName: targetMarket || "",
        industryName: industryCode || "",
        companyTaxType: taxType || "",
        monthlySalesAmount: currentMonthlySales
          ? formatNumber(String(currentMonthlySales).replace(/\./g, ""))
          : "",
        averageBillAmount: estimatedAverageBillingAccount
          ? formatNumber(
              String(estimatedAverageBillingAccount).replace(/\./g, "")
            )
          : "",
        monthlySalesTurnoverAmount: estimatedMonthlySalesTurnover
          ? formatNumber(
              String(estimatedMonthlySalesTurnover).replace(/\./g, "") || ""
            )
          : "",
        currency: "IDR",
        merchantCriteria: criteriaCode || "",
      }));

      if (merchantBank) {
        const { bank, swiftCode, accountNumber, branch, accountName } =
          merchantBank;
        setFormData((prev) => ({
          ...prev,
          bankName: bank || "",
          bankSwiftNumber: swiftCode || "",
          bankNumber: accountNumber || "",
          bankBranchName: branch || "",
          bankAccountName: accountName || "",
        }));
      }
    }
  }, [merchantDetail]);

  const handleChange = (e, name) => {
    const { target } = e;

    if (target) {
      const { name, value } = target;
      let updatedValue = "";

      if (name?.toLowerCase().includes("amount")) {
        updatedValue = formatNumber(value.replace(/\./g, ""));
      } else if (name?.toLowerCase().includes("swift")) {
        updatedValue = value.toUpperCase();
      } else {
        updatedValue = value;
      }

      setFormData((prev) => ({ ...prev, [name]: updatedValue }));
    } else if (name) {
      setFormData((prev) => ({ ...prev, [name]: e }));
    }
  };

  useEffect(() => {
    const {
      merchantCriteria,
      productName,
      marketTargetName,
      industryName,
      monthlySalesAmount,
      monthlySalesTurnoverAmount,
      averageBillAmount,
      bankName,
      bankBranchName,
      bankSwiftNumber,
      bankNumber,
      bankAccountName,
      currency,
    } = formData;

    const progressConditions = [
      merchantCriteria,
      productName,
      marketTargetName,
      industryName,
      monthlySalesAmount,
      monthlySalesTurnoverAmount,
      averageBillAmount,
      bankName,
      bankBranchName || bankSwiftNumber,
      bankNumber,
      bankAccountName,
      currency,
    ];

    const validProgressCount = progressConditions.filter(Boolean).length;
    const progressNumber =
      (validProgressCount / progressConditions.length) * 20;

    setProgressData((prev) => ({
      ...prev,
      section3: Math.ceil(progressNumber),
    }));
  }, [formData, setProgressData]);

  const handlePreviousStep = () => {
    setActiveSectionNumber((prev) => prev - 1);
  };

  const handleDisabledButton = () => {
    const {
      loadingSubmit,
      productName,
      merchantCriteria,
      marketTargetName,
      industryName,
      companyTaxType,
      monthlySalesAmount,
      averageBillAmount,
      monthlySalesTurnoverAmount,
      bankName,
      bankBranchName,
      bankSwiftNumber,
      bankNumber,
      bankAccountName,
      currency,
    } = formData;

    const isFormIncomplete =
      loadingSubmit ||
      !productName ||
      !merchantCriteria ||
      !isValidName(productName) ||
      !marketTargetName ||
      !isValidName(marketTargetName) ||
      !industryName ||
      !companyTaxType ||
      !monthlySalesAmount ||
      !isValidAmount(monthlySalesAmount) ||
      !averageBillAmount ||
      !isValidAmount(averageBillAmount) ||
      !monthlySalesTurnoverAmount ||
      !isValidAmount(monthlySalesTurnoverAmount) ||
      !currency ||
      !bankName ||
      !bankAccountName ||
      !isValidName(bankAccountName) ||
      !bankNumber ||
      !isValidNumber(bankNumber);

    const isFormIDR = !bankBranchName || !isValidName(bankBranchName);
    const isFormUSD = !bankSwiftNumber || !isValidSwift(bankSwiftNumber);

    if (currency === "IDR") {
      return isFormIncomplete || isFormIDR;
    } else {
      return isFormIncomplete || isFormUSD;
    }
  };

  const renderOption = (fieldName) => {
    if (!loadingMerchant) {
      return fieldName === "merchantCriteria"
        ? criteriaList
        : fieldName === "industryName"
        ? industryType
        : fieldName === "companyTaxType"
        ? listTax(t, strings)
        : [];
    }
  };

  const onSubmitBusinessInfo = () => {
    const {
      merchantCriteria,
      productName,
      marketTargetName,
      industryName,
      monthlySalesAmount,
      monthlySalesTurnoverAmount,
      averageBillAmount,
      bankName,
      bankBranchName,
      bankSwiftNumber,
      bankNumber,
      bankAccountName,
      currency,
      companyTaxType,
    } = formData;

    const payload = {
      businessType: userDataLogin?.business_type,
      merchantProduct: productName,
      targetMarket: marketTargetName,
      industryCode: industryName,
      taxType: companyTaxType,
      criteriaCode: merchantCriteria,
      currentMonthlySales: Number(monthlySalesAmount.replace(/\./g, "")),
      estimatedAverageBillingAccount: Number(
        averageBillAmount.replace(/\./g, "")
      ),
      estimatedMonthlySalesTurnover: Number(
        monthlySalesTurnoverAmount.replace(/\./g, "")
      ),
      merchantBank: {
        bank: bankName,
        branch: bankBranchName,
        swiftCode: bankSwiftNumber,
        accountName: bankAccountName,
        accountNumber: bankNumber,
        accountCurrency: currency,
      },
      section: activeSectionNumber + 1,
      businessCompletion: progressData.section3,
      totalCompletion: 20,
    };
    onSubmitMerchantInfo(
      t,
      strings,
      payload,
      activeSectionNumber,
      setActiveSectionNumber,
      activeStepOutlined,
      setActiveStepOutlined,
      setFormData,
      showSnackbar
    );
  };

  return !loadingMerchant ? (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />

      <div className={classes.divFormStepper}>
        <InfoDataCorrection
          errorVerificationData={errorVerificationData}
          t={t}
          strings={strings}
          classes={classes}
        />
        {objectBusinessInfo(t, strings).map((field, index) => {
          switch (field.type) {
            case "select":
              return (
                <AutoCompleteSelect
                  options={renderOption(field.name)}
                  value={formData[field.name]}
                  name={field.name}
                  label={field.label}
                  key={index}
                  onChange={(e) => handleChange(e, field.name)}
                  placeholder={field.placeholder}
                />
              );

            case "companyTaxType":
              return (
                <AutoCompleteSelect
                  options={field.options}
                  value={formData[field.name]}
                  name={field.name}
                  label={field.label}
                  key={index}
                  onChange={(e) => handleChange(e, field.name)}
                  placeholder={field.placeholder}
                />
              );

            default:
              return (
                <Textfields
                  key={index}
                  isHideLabel={true}
                  placeholder={field.placeholder}
                  label={field.label}
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  isValidate={field.isValidate}
                  isOptional={field.isOptional}
                  fullWidth
                />
              );
          }
        })}

        <div className={classes.divBoundaries}>
          <Typography className={classes.textSuperBold18}>
            {t(strings.textBankAccountInformation)}
          </Typography>
          <Typography className={classes.textRegularDarkGray14}>
            {t(strings.textProvideBankInfo)}
          </Typography>
        </div>

        <div className={classes.divLine} />

        {objectBankAccount(t, strings)
          .slice(0, 1)
          .map((field, index) => {
            switch (field.name) {
              case "currency":
                return (
                  <RadioButtons
                    label={field.label}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleChange}
                    options={currencyList()}
                    row={true}
                    key={index}
                  />
                );

              default:
                return null;
            }
          })}

        {formData?.currency === "USD" && (
          <InfoComponent
            severity={"info"}
            message={t(strings.textSwiftCodeMeaning)}
          />
        )}

        <div className={classes.divWrapFourColumn}>
          {objectBankAccount(t, strings)
            .slice(1, objectBankAccount(t, strings).length)
            .reduce((rows, field, index) => {
              if (index % 2 === 0) {
                rows.push([field]);
              } else {
                rows[rows.length - 1].push(field);
              }
              return rows;
            }, [])
            .map((row) => {
              return row.map((field, index) => {
                return renderObjectBank(
                  t,
                  strings,
                  classes,
                  field,
                  index,
                  formData,
                  handleChange,
                  bankList
                );
              });
            })}
        </div>

        <div className={classes.divGroupButton}>
          <Buttons
            loadingSubmit={false}
            label={t(strings.textPrevious)}
            handleDisabledButton={() => false}
            onSubmitData={handlePreviousStep}
            leftIcon={<LeftArrow />}
            isSolid={false}
          />
          <Buttons
            loadingSubmit={formData.loadingSubmit}
            label={t(strings.textNext)}
            handleDisabledButton={handleDisabledButton}
            onSubmitData={onSubmitBusinessInfo}
            rightIcon={<RightIcon />}
          />
        </div>
      </div>
    </>
  ) : (
    <LoadingState />
  );
};

export default FormBusinessInfo;
