import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const onSubmitRequestProd = (
  setLoadingReqProd,
  t,
  string,
  showSnackbar,
  setActiveSectionNumber,
  setActiveStepOutlined
) => {
  setLoadingReqProd(true);
  const headers = headerRequest();
  RequestHttpGateway.post(`/merchant/final`, {}, { headers })
    .then((_res) => {
      setLoadingReqProd(false);
      setActiveStepOutlined((prev) => prev + 1);
      setActiveSectionNumber(0);
    })
    .catch((err) => {
      setLoadingReqProd(false);
      showSnackbar(
        err.response.data.response_message || t(string.textServiceNotAvailable),
        "error"
      );
    });
};

export default onSubmitRequestProd;
