import { useTranslation } from "react-i18next";
import Buttons from "../../General/Component/Buttons";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import classes from "../RegistMerchant.module.css";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import useSnackbar from "../../General/Helper/useSnackbar";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import SelectOptions from "../../General/Component/SelectOptions";
import listCategory from "../Constant/listCategory";
import listIntegrationService from "../Constant/listIntegrationService";
import objectPaymentService from "../Constant/objectPaymentService";
import { Typography } from "@mui/material";
import RightIcon from "../../General/Icon/RightIcon";
import LeftArrow from "../../General/Icon/LeftArrow";
import CheckBox from "../../General/Component/CheckBox";
import FormCheckBox from "../../General/Component/FormCheckBox";
import listDisbursementDetails from "../Constant/listDisbursementDetails";
import onSubmitMerchantInfo from "../Helper/onSubmitMerchantInfo";
import getMerchantDetail from "../Helper/getMerchantDetail";
import { LoadingState } from "../../General/Component/LoadingComp";
import Textfields from "../../General/Component/Textfields";
import InfoDataCorrection from "./InfoDataCorrection";
import CardCC from "./ComponentItem/CardCC";
import getChannel from "../Helper/getChannel";
import getChannelType from "../Helper/getChannelType";
import capitalizedName from "../../General/Helper/capitalizedName";
import CardPayment from "./ComponentItem/CardPayment";

const FormPaymentInfo = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
  errorVerificationData,
  setErrorVerificationData,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const [merchantDetail, setMerchantDetail] = useState(null);
  const [loadingMerchant, setLoadingMerchant] = useState(true);
  const [formData, setFormData] = useState({
    serviceCategory: "",
    notes: "",
    isDisbursement: false,
    loadingSubmit: false,
    isDisabledForm: false,
  });
  const [formDataChannel, setFormDataChannel] = useState({
    creditCard: "",
    installmentPeriod: [],
    installmentMode: [],
    virtualProvider: [],
    debitProvider: [],
    walletProvider: [],
    otcProvider: [],
  });

  const [serviceDetails, setServiceDetails] = useState(listIntegrationService);
  const [disbursementServiceDetails, setDisbursementServiceDetails] = useState(
    listDisbursementDetails
  );
  const [channelList, setChannelList] = useState([]);
  const [channelTypeList, setChannelTypeList] = useState([]);
  const [loadingChannel, setLoadingChannel] = useState(false);
  const [isDisbursementOpen, setIsDisbursementOpen] = useState(false);
  const merchantVerif = useSelector(
    (state) => state.user_data.verificationStatus
  );

  const [categoryOptions, setCategoryOptions] = useState({
    CREDIT: [],
    VIRTUAL_ACCOUNT: [],
    DIRECT_DEBIT: [],
    WALLET: [],
    OTC: [],
  });

  const customOrderList = [
    "CREDIT",
    "VIRTUAL_ACCOUNT",
    "DIRECT_DEBIT",
    "WALLET",
    "OTC",
  ];

  const titles = {
    CREDIT: t(strings.textCreditCard),
    VIRTUAL_ACCOUNT: t(strings.textVA),
    DIRECT_DEBIT: t(strings.textDirectDebit),
    WALLET: t(strings.textWallet),
    OTC: t(strings.textOTC),
  };

  const providerChannel = {
    CREDIT: "creditProvider",
    VIRTUAL_ACCOUNT: "virtualProvider",
    DIRECT_DEBIT: "debitProvider",
    WALLET: "walletProvider",
    OTC: "otcProvider",
  };

  const labels = {
    CREDIT: "",
    VIRTUAL_ACCOUNT: t(strings.textSelectedVirtualAccount),
    DIRECT_DEBIT: t(strings.textSelectedDirectDebit),
    WALLET: t(strings.textSelectedEWallet),
    OTC: t(strings.textSelectedOTC),
  };

  useEffect(() => {
    getMerchantDetail(
      t,
      strings,
      activeSectionNumber,
      activeStepOutlined,
      setMerchantDetail,
      setLoadingMerchant,
      showSnackbar
    );
    getChannel(setChannelList, setLoadingChannel);
    getChannelType(setChannelTypeList, setLoadingChannel);
    if (merchantVerif) {
      setErrorVerificationData(merchantVerif?.reason);
      if (merchantVerif.production === "RESUBMIT") {
        setFormData((data) => ({ ...data, isDisabledForm: true }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSectionNumber, activeStepOutlined, showSnackbar, t, merchantVerif]);

  useEffect(() => {
    if (formData.serviceCategory) {
      let data = [];
      listIntegrationService.forEach((item) => {
        // DON'T REMOVE THESE CODE
        // if (formData.serviceCategory === "API Integration") {
        //   if (
        //     item.name === "Api Payment Gateway" ||
        //     item.name === "Api Payment Page"
        //   ) {
        //     data.push(item);
        //   }
        // } else if (formData.serviceCategory === "Non Integration") {
        //   if (
        //     item.name === "Instapay Dashboard" ||
        //     item.name === "Email Invoice"
        //   ) {
        //     data.push(item);
        //   }
        // }
        if (
          formData.serviceCategory === "API Integration" ||
          formData.serviceCategory === "Non Integration"
        ) {
          if (
            item.name === "Api Payment Page" ||
            item.name === "Api Payment Gateway"
          ) {
            data.push(item);
          }
        }
      });
      if (merchantDetail?.merchantServiceDetails?.length > 0) {
        const check = data.map((service) => {
          const isChecked = merchantDetail.merchantServiceDetails?.some(
            (merchantService) =>
              merchantService.serviceDetailName === service.name
          );
          return { ...service, checked: isChecked };
        });
        setServiceDetails(check);
      } else {
        setServiceDetails(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.serviceCategory, merchantDetail]);

  useEffect(() => {
    let filterPaymentListById = (paymentChannels, id) =>
      paymentChannels
        .filter((item) => item.type === id)
        .map((data) => ({
          ...data,
        }));

    let paymentChannels = channelList;

    let creditCardList = filterPaymentListById(paymentChannels, "CREDIT");
    let directDebitList = filterPaymentListById(
      paymentChannels,
      "DIRECT_DEBIT"
    );
    let virtualAccountList = filterPaymentListById(
      paymentChannels,
      "VIRTUAL_ACCOUNT"
    );
    let walletList = filterPaymentListById(paymentChannels, "WALLET");
    let OTCList = filterPaymentListById(paymentChannels, "OTC");

    setCategoryOptions((prev) => ({
      ...prev,
      CREDIT: creditCardList,
      DIRECT_DEBIT: directDebitList,
      VIRTUAL_ACCOUNT: virtualAccountList,
      WALLET: walletList,
      OTC: OTCList,
    }));

    let installmentPeriod = [];
    let installmentMode = [];

    creditCardList[0]?.installment?.forEach((data) => {
      installmentPeriod.push({
        name: data,
        label: `${data} ${t(strings.textMonth)}`,
        checked: false,
      });
    });

    const customCCType = ["regular", "recurring", "installment"];

    creditCardList[0]?.mode
      ?.sort(
        (itemA, itemB) =>
          customCCType.indexOf(itemA) - customCCType.indexOf(itemB)
      )
      .map((data) => {
        return installmentMode.push({
          label: capitalizedName(data),
          value: data,
        });
      });
    installmentMode.push({
      label: t(strings.textNone),
      value: "none",
    });

    setFormDataChannel((prev) => ({
      ...prev,
      installmentPeriod: installmentPeriod,
      installmentMode: installmentMode,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelList]);

  useEffect(() => {
    if (merchantDetail) {
      const {
        serviceCategory,
        isDisbursement,
        merchantChannels,
        merchantServiceDetails,
        notes,
      } = merchantDetail;

      const updatedPaymentChannels = channelList?.map((channel) => {
        const isChecked = merchantChannels?.some(
          (merchantChannel) => merchantChannel?.acquiring === channel.acquiring
        );
        return { ...channel, checked: isChecked };
      });

      // CREDIT CARD
      let creditData = merchantChannels?.filter(
        (data) => data.acquiring === "CREDIT"
      )[0];
      let installmentData = creditData?.channelInstallment;
      let updatedInstallmentPeriod = [];
      if (
        formDataChannel.installmentPeriod?.length > 0 &&
        installmentData?.length > 0
      ) {
        updatedInstallmentPeriod = formDataChannel.installmentPeriod?.map(
          (item) => ({
            ...item,
            checked: installmentData?.includes(item.name),
          })
        );
        setFormDataChannel((prev) => ({
          ...prev,
          installmentPeriod: updatedInstallmentPeriod,
        }));
      }

      // VIRTUAL ACCOUNT
      let virtualData = [];
      updatedPaymentChannels
        .filter(
          (data) => data.checked === true && data.type === "VIRTUAL_ACCOUNT"
        )
        .forEach((item) => {
          const checkedModes = item.mode.map((mode) => {
            return {
              name: mode,
              checked: true,
            };
          });
          virtualData.push({
            provider: {
              name: item.acquiring,
              checked: true,
            },
            modes: [...checkedModes],
            other: item,
          });
        });

      // DIRECT DEBIT
      let debitData = [];
      updatedPaymentChannels
        .filter((data) => data.checked === true && data.type === "DIRECT_DEBIT")
        .forEach((item) => {
          const checkedModes = item.mode.map((mode) => {
            return {
              name: mode,
              checked: true,
            };
          });
          debitData.push({
            provider: {
              name: item.acquiring,
              checked: true,
            },
            modes: [...checkedModes],
            other: item,
          });
        });

      // E-WALLET/E-MONEY
      let walletData = [];
      updatedPaymentChannels
        .filter((data) => data.checked === true && data.type === "WALLET")
        .forEach((item) => {
          const checkedModes = item.mode.map((mode) => {
            return {
              name: mode,
              checked: true,
            };
          });
          walletData.push({
            provider: {
              name: item.acquiring,
              checked: true,
            },
            modes: [...checkedModes],
            other: item,
          });
        });

      // DIRECT DEBIT
      let OTCData = [];
      updatedPaymentChannels
        .filter((data) => data.checked === true && data.type === "OTC")
        .forEach((item) => {
          const checkedModes = item.mode.map((mode) => {
            return {
              name: mode,
              checked: true,
            };
          });
          OTCData.push({
            provider: {
              name: item.acquiring,
              checked: true,
            },
            modes: [...checkedModes],
            other: item,
          });
        });

      //
      const updateDisbursementService = listDisbursementDetails?.map(
        (disburse) => {
          const isChecked = merchantServiceDetails?.some(
            (disburseService) =>
              disburseService.serviceDetailName === disburse.name
          );
          return { ...disburse, checked: isChecked };
        }
      );

      if (serviceCategory === "Disbursement Only") {
        setIsDisbursementOpen(true);
      }

      setDisbursementServiceDetails(updateDisbursementService);
      setFormDataChannel((prev) => ({
        ...prev,
        virtualProvider: virtualData,
        debitProvider: debitData,
        walletProvider: walletData,
        otcProvider: OTCData,
        creditCard: creditData?.channelMode[0] || "none",
      }));
      setFormData((prev) => {
        if (
          prev.serviceCategory !== serviceCategory ||
          prev.isDisbursement !== isDisbursement
        ) {
          return {
            ...prev,
            serviceCategory: serviceCategory,
            isDisbursement: isDisbursement,
            notes: notes,
          };
        }
        return prev;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetail, channelList, formDataChannel.installmentMode]);

  useEffect(() => {
    calculateProgressValue();
    showDisbursementGateway();
    handleDisabledButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, serviceDetails, formDataChannel, disbursementServiceDetails]);

  const handlePreviousStep = () => {
    setActiveSectionNumber((prev) => prev - 1);
  };

  const handleChange = (e, nameCheckbox) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
      if (name === "serviceCategory" && value === "Disbursement Only") {
        setIsDisbursementOpen(true);
        setFormData((prev) => ({ ...prev, isDisbursement: true }));
      } else {
        setIsDisbursementOpen(false);
        setFormData((prev) => ({ ...prev, isDisbursement: false }));
      }
    } else {
      setFormData((prev) => ({
        ...prev,
        [nameCheckbox]: e,
      }));
    }
  };

  //Credit Card
  const handleChangeChannelType = (e, nameCheckbox) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormDataChannel((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setFormDataChannel((prev) => ({
        ...prev,
        installmentPeriod: prev.installmentPeriod.map((item) =>
          item.name === nameCheckbox
            ? { ...item, checked: !item.checked }
            : item
        ),
      }));
    }
  };

  //Virtual Account, Debit, Wallet, OTC
  const handleChangeChannels = (
    e,
    providerName,
    additionalName,
    providerTypes,
    allItem
  ) => {
    setFormDataChannel((prev) => {
      const updatedProvider = prev[providerTypes]?.map((data) =>
        data?.provider.name === providerName
          ? { ...data, provider: { ...data.provider, checked: e } }
          : data
      );

      if (
        !updatedProvider?.some((data) => data.provider.name === providerName)
      ) {
        updatedProvider?.push({
          provider: { name: providerName, checked: e },
          modes: [],
          other: { ...allItem },
        });
      }
      return { ...prev, [providerTypes]: updatedProvider };
    });
  };

  const handleChangeChannelsMode = (
    e,
    modeName,
    providerName,
    providerTypes
  ) => {
    setFormDataChannel((prev) => {
      const updatedProvider = prev[providerTypes]?.map((provider) => {
        if (provider.provider.name === providerName) {
          const updatedModes = provider.modes.some(
            (mode) => mode.name === modeName
          )
            ? provider.modes.map((mode) =>
                mode.name === modeName ? { ...mode, checked: e } : mode
              )
            : [...provider.modes, { name: modeName, checked: e }];

          return {
            ...provider,
            modes: updatedModes,
          };
        }
        return provider;
      });
      return { ...prev, [providerTypes]: updatedProvider };
    });
  };

  const handleServiceDetailChange = (isChecked, name) => {
    setServiceDetails((prevState) =>
      prevState?.map((checkbox) =>
        checkbox.name === name ? { ...checkbox, checked: isChecked } : checkbox
      )
    );
  };

  const handleDisbursementDetailsChange = (isChecked, name) => {
    setDisbursementServiceDetails((prevState) =>
      prevState?.map((checkbox) =>
        checkbox.name === name ? { ...checkbox, checked: isChecked } : checkbox
      )
    );
  };

  const calculateProgressValue = () => {
    let progressConditions = [formData.serviceCategory];
    let serviceItem = serviceDetails?.filter((data) => data.checked).length;
    const isCredit = formDataChannel.creditCard;
    const mappedVA = formDataChannel.virtualProvider?.filter(
      (item) => item.provider.checked
    ).length;
    const mappedDebit = formDataChannel.debitProvider?.filter(
      (item) => item.provider.checked
    ).length;
    const mappedWallet = formDataChannel.walletProvider?.filter(
      (item) => item.provider.checked
    ).length;
    const mappedOTC = formDataChannel.otcProvider?.filter(
      (item) => item.provider.checked
    ).length;

    let disbursementItem = disbursementServiceDetails.filter(
      (data) => data.checked
    ).length;

    if (formData.serviceCategory === "Disbursement Only") {
      if (formData.isDisbursement) {
        progressConditions.push(formData.isDisbursement, disbursementItem);
      }
    } else {
      progressConditions.push(
        serviceItem > 0,
        isCredit,
        mappedVA > 0,
        mappedDebit > 0,
        mappedWallet > 0,
        mappedOTC > 0
      );
      if (formData.isDisbursement) {
        progressConditions.push(formData.isDisbursement, disbursementItem > 0);
      }
    }

    const validProgressCount = progressConditions.filter(Boolean).length;
    const progressNumber =
      (validProgressCount / progressConditions.length) * 20;

    setProgressData((prev) => ({
      ...prev,
      section4: Math.ceil(progressNumber),
    }));
  };

  const showFormIntegration = (field, index) => {
    return (
      <div key={index} className={classes.divFormService}>
        <CheckBox
          key={index}
          name={field}
          title={t(strings.textDisbursementService)}
          options={serviceDetails}
          disabled={formData.isDisabledForm}
          columnsPerRow={1}
          handleChange={handleServiceDetailChange}
        />
        <div className={classes.divLine} />
      </div>
    );
  };

  const handleDisableAddPayment = (cardName) => {
    let channel = formDataChannel[cardName]?.filter(
      (data) => data.provider.checked === true && data.modes.length > 0
    );
    return !channel.length > 0;
  };

  const handleDeleteProvider = (provider, channel) => {
    const afterRemoveItem = formDataChannel[channel].filter(
      (data) => data.provider?.name !== provider
    );
    setFormDataChannel((prev) => ({ ...prev, [channel]: afterRemoveItem }));
  };

  const showChannels = (field, index) => {
    if (!loadingChannel) {
      return (
        <div key={index} className={classes.divFormService}>
          <div className={classes.divStartContent}>
            <Typography className={classes.textSuperBold18}>
              {t(strings.textSelectPaymentChannel)}
            </Typography>
            <Typography className={classes.textRegularDarkGray14}>
              {t(strings.textSelectPaymentChannelSubtitle)}
            </Typography>
          </div>

          <div className={classes.divObjectPayment}>
            {channelTypeList
              .sort(
                (itemA, itemB) =>
                  customOrderList.indexOf(itemA.channelTypeName) -
                  customOrderList.indexOf(itemB.channelTypeName)
              )
              .map((data, idx) => {
                const keyId = `${data.id}-${idx}`;

                let removedRedundantName = [
                  ...new Map(
                    categoryOptions[data.channelTypeName].map((item) => [
                      item.acquiring,
                      item,
                    ])
                  ).values(),
                ];

                if (data.channelTypeName === "CREDIT") {
                  return (
                    <CardCC
                      key={keyId}
                      t={t}
                      strings={strings}
                      title={titles[data.channelTypeName]}
                      subTitle={t(strings.textIncludeVAT)}
                      formDataChannel={formDataChannel}
                      data={formDataChannel.installmentMode}
                      period={formDataChannel.installmentPeriod}
                      handleChange={handleChangeChannelType}
                      disabled={formData.isDisabledForm}
                    />
                  );
                } else {
                  return (
                    <CardPayment
                      key={keyId}
                      cardName={providerChannel[data.channelTypeName]}
                      cardModeName={"mode"}
                      t={t}
                      strings={strings}
                      title={titles[data.channelTypeName]}
                      subTitle={t(strings.textIncludeVAT)}
                      labelSelected={labels[data.channelTypeName]}
                      data={removedRedundantName}
                      formDataChannel={formDataChannel}
                      handleChange={handleChangeChannels}
                      handleChangeMode={handleChangeChannelsMode}
                      handleDisableAddPayment={handleDisableAddPayment}
                      handleDeleteProvider={handleDeleteProvider}
                      disabled={formData.isDisabledForm}
                    />
                  );
                }
              })}
          </div>
        </div>
      );
    } else {
      return (
        <div key={index} className={classes.divFormService}>
          <LoadingState />
        </div>
      );
    }
  };

  const handleDisabledButton = () => {
    const resultServiceDetails = serviceDetails.filter((data) => data.checked);
    const checkedModeCC = formDataChannel.installmentPeriod?.filter(
      (item) => item.checked
    );
    const mappedVA = formDataChannel.virtualProvider?.filter(
      (item) => item.provider.checked && item.modes.length > 0
    );
    const mappedDebit = formDataChannel.debitProvider?.filter(
      (item) => item.provider.checked && item.modes.length > 0
    );
    const mappedWallet = formDataChannel.walletProvider?.filter(
      (item) => item.provider.checked && item.modes.length > 0
    );

    const mappedOTC = formDataChannel.otcProvider?.filter(
      (item) => item.provider.checked && item.modes.length > 0
    );

    let isInstallment = formDataChannel.creditCard
      ? formDataChannel.creditCard === "installment"
        ? checkedModeCC.length < 1
        : false
      : true;

    return (
      formData.loadingSubmit ||
      !formData.serviceCategory ||
      resultServiceDetails?.length < 1 ||
      isInstallment ||
      (mappedVA.length < 1 &&
        mappedDebit.length < 1 &&
        mappedWallet.length < 1 &&
        mappedOTC.length < 1)
    );
  };

  const handleDisabledButtonDisburse = () => {
    const resultDisbursement =
      disbursementServiceDetails
        .filter((data) => data.checked)
        .map((data) => data.name) || [];

    return formData.isDisbursement && resultDisbursement.length < 1
      ? true
      : false;
  };

  const goToDisbursement = () => {
    if (formData.serviceCategory === "Disbursement Only") {
      handlePreviousStep();
    } else {
      setIsDisbursementOpen(!isDisbursementOpen);
    }
  };

  const showDisbursementGateway = () => {
    if (
      formData.serviceCategory === "API Integration" ||
      formData.serviceCategory === "Non Integration"
    ) {
      return (
        <>
          <div className={classes.divRowSpace}>
            <Typography className={classes.textBold600Darker16}>
              {t(strings.textAskForDisbursement)}
            </Typography>
            <FormCheckBox
              checked={formData?.isDisbursement}
              label={""}
              columnsPerRow={1}
              name={"isDisbursement"}
              onChange={handleChange}
              icon={null}
              disabled={formData.isDisabledForm}
            />
          </div>
          {formData.isDisbursement && (
            <CheckBox
              name={"disbursementServiceDetails"}
              title={t(strings.textDisbursementService)}
              options={disbursementServiceDetails}
              columnsPerRow={1}
              handleChange={handleDisbursementDetailsChange}
              disabled={formData.isDisabledForm}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {objectPaymentService(t, strings).map((field, index) => {
            switch (field.name) {
              case "serviceCategory":
                return (
                  <SelectOptions
                    key={index}
                    label={field.label}
                    name={field.name}
                    placeholder={field.placeholder}
                    value={formData[field.name]}
                    listData={listCategory(t, strings)}
                    handleChange={handleChange}
                  />
                );

              // case "disbursementServiceDetails":
              //   return (
              //     <CheckBox
              //       key={index}
              //       name={field.name}
              //       title={t(strings.textSelectDisbursementService)}
              //       options={disbursementServiceDetails}
              //       columnsPerRow={1}
              //       handleChange={handleDisbursementDetailsChange}
              //     />
              //   );

              default:
                return null;
            }
          })}
        </>
      );
    }
  };

  const onSubmitPaymentInfo = () => {
    const resultServiceDetails =
      serviceDetails.filter((data) => data.checked).map((data) => data.name) ||
      [];

    let allChannels = [];
    //CREDIT CARD
    let checkedModeCC = formDataChannel.installmentPeriod
      ?.filter((item) => item.checked)
      .map((data) => data.name);

    const isNoCC = formDataChannel.creditCard === "none" ? true : false;

    const resultCC = {
      merchantChannelType: "CREDIT",
      acquiring: "CREDIT",
      channelMode: isNoCC ? [""] : [formDataChannel.creditCard],
      channelInstallment:
        formDataChannel.creditCard !== "installment" ? [] : checkedModeCC,
    };

    if (!isNoCC) {
      allChannels.push(resultCC);
    }

    //VIRTUAL CARD
    const mappedVA = formDataChannel.virtualProvider
      ?.filter((item) => item.provider.checked)
      .map((data) => {
        let checkedModeVA = data.modes
          ?.filter((item) => item.checked)
          .map((data) => data.name);
        return {
          merchantChannelType: data.other.type,
          acquiring: data.provider.name,
          channelMode: checkedModeVA || [],
        };
      });

    if (mappedVA.length > 0) {
      allChannels.push(...mappedVA);
    }

    //DEBIT CARD
    const mappedDebit = formDataChannel.debitProvider
      ?.filter((item) => item.provider.checked)
      .map((data) => {
        let checkedMode = data.modes
          ?.filter((item) => item.checked)
          .map((data) => data.name);
        return {
          merchantChannelType: data.other.type,
          acquiring: data.provider.name,
          channelMode: checkedMode || [],
        };
      });
    if (mappedDebit.length > 0) {
      allChannels.push(...mappedDebit);
    }

    //WALLET
    const mappedWallet = formDataChannel.walletProvider
      ?.filter((item) => item.provider.checked)
      .map((data) => {
        let checkedMode = data.modes
          ?.filter((item) => item.checked)
          .map((data) => data.name);
        return {
          merchantChannelType: data.other.type,
          acquiring: data.provider.name,
          channelMode: checkedMode || [],
        };
      });
    if (mappedWallet.length > 0) {
      allChannels.push(...mappedWallet);
    }
    //OTC
    const mappedOTC = formDataChannel.otcProvider
      ?.filter((item) => item.provider.checked)
      .map((data) => {
        let checkedMode = data.modes
          ?.filter((item) => item.checked)
          .map((data) => data.name);
        return {
          merchantChannelType: data.other.type,
          acquiring: data.provider.name,
          channelMode: checkedMode || [],
        };
      });
    if (mappedOTC.length > 0) {
      allChannels.push(...mappedOTC);
    }

    const resultDisbursement = disbursementServiceDetails
      .filter((data) => data.checked)
      .map((data) => data.name);

    const payload = {
      businessType: userDataLogin?.business_type,
      serviceCategory: formData.serviceCategory,
      serviceDetails: resultServiceDetails,
      isDisbursement: formData.isDisbursement,
      disbursementServiceDetails: resultDisbursement,
      channels:
        formData.serviceCategory === "Disbursement Only"
          ? []
          : [...allChannels],
      notes: formData.notes || "-",
      section: activeSectionNumber + 1,
      serviceFeeCompletion: progressData.section4,
      totalCompletion: 20,
    };

    onSubmitMerchantInfo(
      t,
      strings,
      payload,
      activeSectionNumber,
      setActiveSectionNumber,
      activeStepOutlined,
      setActiveStepOutlined,
      setFormData,
      showSnackbar
    );
  };

  return !loadingMerchant ? (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />

      <div className={classes.divFormStepper}>
        <InfoDataCorrection
          errorVerificationData={errorVerificationData}
          t={t}
          strings={strings}
          classes={classes}
        />
        {!isDisbursementOpen ? (
          <>
            {objectPaymentService(t, strings).map((field, index) => {
              switch (field.name) {
                case "serviceCategory":
                  return (
                    <SelectOptions
                      key={index}
                      label={field.label}
                      name={field.name}
                      placeholder={field.placeholder}
                      value={formData[field.name]}
                      listData={listCategory(t, strings)}
                      handleChange={handleChange}
                      isDisabled={formData.isDisabledForm}
                    />
                  );
                case "serviceDetails":
                  return (
                    (formData.serviceCategory === "API Integration" ||
                      formData.serviceCategory === "Non Integration") &&
                    showFormIntegration(field, index)
                  );

                case "channels":
                  return (
                    (formData.serviceCategory === "API Integration" ||
                      formData.serviceCategory === "Non Integration") &&
                    showChannels(field, index)
                  );

                case "notes":
                  return (
                    (formData.serviceCategory === "API Integration" ||
                      formData.serviceCategory === "Non Integration") && (
                      <Textfields
                        key={index}
                        placeholder={field.placeholder}
                        name={field.name}
                        label={field.label}
                        type={field.type}
                        value={formData[field.name]}
                        onChange={handleChange}
                        isOptional={field.isOptional}
                        isHideLabel={field.isHideLabel}
                        isValidate={field.isValidate}
                        disabled={formData.isDisabledForm}
                      />
                    )
                  );

                default:
                  return null;
              }
            })}

            <div className={classes.divGroupButton}>
              <Buttons
                loadingSubmit={false}
                label={t(strings.textPrevious)}
                handleDisabledButton={() => false}
                onSubmitData={handlePreviousStep}
                leftIcon={<LeftArrow />}
                isSolid={false}
              />
              <Buttons
                loadingSubmit={formData.loadingSubmit}
                label={t(strings.textNext)}
                handleDisabledButton={handleDisabledButton}
                // onSubmitData={goToDisbursement} nanti di-apply kalo udah integrasi
                onSubmitData={onSubmitPaymentInfo}
                rightIcon={<RightIcon />}
              />
            </div>
          </>
        ) : (
          <>
            {showDisbursementGateway()}
            <div className={classes.divGroupButton}>
              <Buttons
                loadingSubmit={false}
                label={t(strings.textPrevious)}
                handleDisabledButton={() => false}
                onSubmitData={goToDisbursement}
                leftIcon={<LeftArrow />}
                isSolid={false}
              />
              <Buttons
                loadingSubmit={formData.loadingSubmit}
                label={t(strings.textNext)}
                handleDisabledButton={handleDisabledButtonDisburse}
                onSubmitData={onSubmitPaymentInfo}
                rightIcon={<RightIcon />}
              />
            </div>
          </>
        )}
      </div>
    </>
  ) : (
    <LoadingState />
  );
};

export default FormPaymentInfo;
