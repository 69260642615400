import UploadIcon from "../../General/Icon/UploadIcon";

const objectMerchantNonCorporate = (t, strings) => [
  {
    name: "merchantName",
    placeholder: t(strings.textPlaceholderMerchantName),
    label: t(strings.textMerchantName),
    type: "text",
    isValidate: true,
  },
  {
    name: "countryName",
    placeholder: t(strings.textPlaceholderMerchantCountry),
    label: t(strings.textMerchantCountry),
    type: "autocomplete",
    isValidate: true,
  },
  {
    name: "provinceName",
    placeholder: t(strings.textPlaceholderMerchantProvince),
    label: t(strings.textMerchantProvince),
    type: "text",
    isValidate: true,
  },
  {
    name: "cityName",
    placeholder: t(strings.textPlaceholderMerchantCity),
    label: t(strings.textMerchantCity),
    type: "text",
    isValidate: true,
  },
  {
    name: "postCode",
    placeholder: t(strings.textPlaceholderMerchantPostCode),
    label: t(strings.textMerchantPostCode),
    type: "text",
    isValidate: true,
  },
  {
    name: "urlWeb",
    placeholder: t(strings.textPlaceholderWebsiteURL),
    label: t(strings.textURLWeb),
    type: "text",
    isValidate: true,
  },
  {
    name: "npwpNumberCompany",
    placeholder: t(strings.textPlaceholderCompanyNPWP),
    label: t(strings.textCompanyNPWP),
    type: "text",
    isValidate: true,
  },
  {
    name: "filePaymentFlow",
    placeholder: t(strings.textPlaceholderAddDocument),
    icon: <UploadIcon />,
    label: t(strings.textPaymentProcessFlow),
    type: "file",
    extensionFile: ".pdf",
    notes: t(strings.textInfoDocMax5MB),
    isOptional: false,
  },
  {
    name: "fileMerchantLogo",
    placeholder: t(strings.textPlaceholderCompanyLogo),
    icon: <UploadIcon />,
    label: t(strings.textMerchantLogo),
    type: "file",
    extensionFile: ".png",
    notes: t(strings.textInfoImageMax5MB),
    isOptional: false,
  },
  {
    name: "referralName",
    placeholder: t(strings.textPlaceholderReferralName),
    label: t(strings.textMROName),
    type: "autocomplete",
    isValidate: true,
    isAny: true,
  },
  {
    name: "directorName",
    placeholder: t(strings.textPlaceholderBusinessOwnerName),
    label: t(strings.textBusinessOwnerName),
    type: "text",
    isValidate: true,
  },
  {
    name: "ktpNumberDirector",
    placeholder: t(strings.textPlaceholderBusinessOwnerKTP),
    label: t(strings.textBusinessOwnerKTP),
    type: "text",
    isValidate: true,
  },
  {
    name: "directorAddress",
    placeholder: `${t(
      strings.textExample
    )}: Jl. Raya Sejahtera No. 12, Kelapa Gading, Jakarta, DKI Jakarta 14230, Indonesia`,
    label: t(strings.textBusinessOwnerAddress),
    type: "text",
    isValidate: true,
  },
];

export default objectMerchantNonCorporate;
