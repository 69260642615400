import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import classes from "../Style/general.module.css";
import CloseIconAction from "./CloseIconAction";

const DialogPopUp = ({
  open,
  subTitle,
  maxWidth,
  onClose,
  title,
  content,
  actions,
}) => {
  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth>
      <DialogTitle className={classes.divDialogTitle}>
        <div>
          <Typography className={classes.textBold600BlackH4}>
            {title}
          </Typography>
          {subTitle && (
            <Typography className={classes.textRegularDarkGray14}>
              {subTitle}
            </Typography>
          )}
        </div>
        <CloseIconAction onClose={onClose} />
      </DialogTitle>
      <DialogContent>{content || null}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default DialogPopUp;
