import classes from "../RegistMerchant.module.css";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import { useTranslation } from "react-i18next";
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import Buttons from "../../General/Component/Buttons";
import { useEffect, useState } from "react";
import DialogFormPopUp from "../../General/Component/DialogFormPopUp";
import ContactSection from "../../General/Component/ContactSection";
import { CardInformation } from "../../General/Component/CardInformation";
import getVerificationStatus from "../Helper/getVerificationStatus";
import { LoadingState } from "../../General/Component/LoadingComp";
import useSnackbar from "../../General/Helper/useSnackbar";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import getCredentialStaging from "../Helper/getCredentialStaging";
import onSubmitRequestProd from "../Helper/onSubmitRequestProd";
import { useDispatch, useSelector } from "react-redux";
import { fetchMerchantVerification } from "../../Store/userSlice";
import getMerchantPreview from "../Helper/getMerchantPreview";
import getBucketStorageGcp from "../Helper/getBucketStorageGcp";
import DialogPopUp from "../../General/Component/DialogPopUp";
import ElementToPrint, { downloadReceipt } from "./ElementToPrint";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px solid #CFDFEC",
    width: "50%",
    // borderBottom: "1px solid #CFDFEC",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FCFCFC",
  },
}));

const WebStaging = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
  forBtnBack,
  setForBtnBack,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const [isStaging, setIsStaging] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataStaging, setDataStaging] = useState([]);
  const [statusStaging, setStatusStaging] = useState(null);
  const [, setLoadingReqProd] = useState(false);
  const [, setIsProd] = useState(false);
  const [, setStatusProd] = useState(null);
  const detailParam = false;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [logo, setLogo] = useState("");
  const [openPdfContent, setOpenPdfContent] = useState(false);
  const [pdfContent, setPdfContent] = useState({
    page1: [],
    page2: [],
    page3: [],
    page4: [],
  });
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const merchantVerif = useSelector(
    (state) => state.user_data.verificationStatus
  );

  const handleCloseContactUs = () => {
    setOpenContactUs(false);
  };

  useEffect(() => {
    const mediaId = data[0]?.details[0]?.list[7].value;
    if (mediaId) {
      getBucketStorageGcp(mediaId, setLogo);
    }
  }, [data]);

  useEffect(() => {
    getMerchantPreview(
      t,
      strings,
      setData,
      setLoading,
      setPdfContent,
      userDataLogin
    );
  }, [t, userDataLogin]);

  useEffect(() => {
    getVerificationStatus(
      setLoading,
      setIsStaging,
      setActiveStepOutlined,
      setActiveSectionNumber,
      t,
      strings,
      showSnackbar,
      setStatusStaging,
      detailParam,
      setIsProd,
      setStatusProd,
      forBtnBack
    );
    dispatch(fetchMerchantVerification());
  }, [
    detailParam,
    dispatch,
    setActiveSectionNumber,
    setActiveStepOutlined,
    showSnackbar,
    t,
    forBtnBack,
  ]);

  useEffect(() => {
    if (statusStaging) {
      getCredentialStaging(
        setLoading,
        setIsStaging,
        setDataStaging,
        t,
        strings,
        showSnackbar
      );
    }
  }, [statusStaging, showSnackbar, t]);

  const submitRequestProd = () => {
    if (
      merchantVerif?.staging === "DONE" &&
      merchantVerif?.production === "RESUBMIT REQUEST"
    ) {
      onSubmitRequestProd(
        setLoadingReqProd,
        t,
        strings,
        showSnackbar,
        setActiveSectionNumber,
        setActiveStepOutlined
      );
    } else if (
      merchantVerif?.staging === "DONE" &&
      merchantVerif?.production !== null
    ) {
      setActiveStepOutlined((prev) => prev + 1);
      setActiveSectionNumber(0);
    } else {
      onSubmitRequestProd(
        setLoadingReqProd,
        t,
        strings,
        showSnackbar,
        setActiveSectionNumber,
        setActiveStepOutlined
      );
    }
  };

  return !loading ? (
    <>
      <div className={classes.divFormStepper}>
        <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />
        <DialogFormPopUp
          open={openContactUs}
          onClose={handleCloseContactUs}
          maxWidth={"xs"}
        />
        <div>
          <DialogPopUp
            open={openPdfContent}
            maxWidth={"sm"}
            title={t(strings.textPreviewData)}
            onClose={() => setOpenPdfContent(false)}
            content={
              <ElementToPrint logo={logo} data={data} pdfContent={pdfContent} />
            }
            actions={
              <div>
                <Buttons
                  loadingSubmit={false}
                  label={t(strings.textExportDataToPdf)}
                  handleDisabledButton={() => false}
                  onSubmitData={downloadReceipt}
                  leftIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M17.5 11.25V16.25C17.5 16.4158 17.4342 16.5747 17.3169 16.6919C17.1997 16.8092 17.0408 16.875 16.875 16.875H3.125C2.95924 16.875 2.80027 16.8092 2.68306 16.6919C2.56585 16.5747 2.5 16.4158 2.5 16.25V11.25C2.5 11.0842 2.56585 10.9253 2.68306 10.8081C2.80027 10.6908 2.95924 10.625 3.125 10.625C3.29076 10.625 3.44973 10.6908 3.56694 10.8081C3.68415 10.9253 3.75 11.0842 3.75 11.25V15.625H16.25V11.25C16.25 11.0842 16.3158 10.9253 16.4331 10.8081C16.5503 10.6908 16.7092 10.625 16.875 10.625C17.0408 10.625 17.1997 10.6908 17.3169 10.8081C17.4342 10.9253 17.5 11.0842 17.5 11.25ZM9.55781 11.6922C9.61586 11.7503 9.68479 11.7964 9.76066 11.8279C9.83654 11.8593 9.91787 11.8755 10 11.8755C10.0821 11.8755 10.1635 11.8593 10.2393 11.8279C10.3152 11.7964 10.3841 11.7503 10.4422 11.6922L13.5672 8.56719C13.6253 8.50912 13.6713 8.44018 13.7027 8.36431C13.7342 8.28844 13.7503 8.20712 13.7503 8.125C13.7503 8.04288 13.7342 7.96156 13.7027 7.88569C13.6713 7.80982 13.6253 7.74088 13.5672 7.68281C13.5091 7.62474 13.4402 7.57868 13.3643 7.54725C13.2884 7.51583 13.2071 7.49965 13.125 7.49965C13.0429 7.49965 12.9616 7.51583 12.8857 7.54725C12.8098 7.57868 12.7409 7.62474 12.6828 7.68281L10.625 9.74141V2.5C10.625 2.33424 10.5592 2.17527 10.4419 2.05806C10.3247 1.94085 10.1658 1.875 10 1.875C9.83424 1.875 9.67527 1.94085 9.55806 2.05806C9.44085 2.17527 9.375 2.33424 9.375 2.5V9.74141L7.31719 7.68281C7.19991 7.56554 7.04085 7.49965 6.875 7.49965C6.70915 7.49965 6.55009 7.56554 6.43281 7.68281C6.31554 7.80009 6.24965 7.95915 6.24965 8.125C6.24965 8.29085 6.31554 8.44991 6.43281 8.56719L9.55781 11.6922Z"
                        fill="#675B5E"
                      />
                    </svg>
                  }
                  isSolid={true}
                />
              </div>
            }
          />
        </div>
        <div className={classes.divWebStaging}>
          {isStaging ? (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Typography className={classes.textInfoMerchantWs}>
                    {t(strings.textInformasiAccountMerchant)}
                  </Typography>
                  <Typography className={classes.subTextInfoMerchantWs}>
                    {t(strings.textInfoMerchantAndWs)}
                  </Typography>
                </div>
                <div className={classes.divForExport}>
                  <div style={{ width: "170px" }}>
                    <Buttons
                      loadingSubmit={false}
                      label={"Preview Data"}
                      handleDisabledButton={() => false}
                      onSubmitData={() => setOpenPdfContent(true)}
                      leftIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                        >
                          <path
                            d="M16.6922 5.68281L13.5672 2.55781C13.5091 2.49979 13.4402 2.45378 13.3643 2.42241C13.2884 2.39105 13.2071 2.37494 13.125 2.375H6.875C6.54348 2.375 6.22554 2.5067 5.99112 2.74112C5.7567 2.97554 5.625 3.29348 5.625 3.625V4.875H4.375C4.04348 4.875 3.72554 5.0067 3.49112 5.24112C3.2567 5.47554 3.125 5.79348 3.125 6.125V17.375C3.125 17.7065 3.2567 18.0245 3.49112 18.2589C3.72554 18.4933 4.04348 18.625 4.375 18.625H13.125C13.4565 18.625 13.7745 18.4933 14.0089 18.2589C14.2433 18.0245 14.375 17.7065 14.375 17.375V16.125H15.625C15.9565 16.125 16.2745 15.9933 16.5089 15.7589C16.7433 15.5245 16.875 15.2065 16.875 14.875V6.125C16.8751 6.0429 16.859 5.96159 16.8276 5.88572C16.7962 5.80985 16.7502 5.7409 16.6922 5.68281ZM10.625 15.5H6.875C6.70924 15.5 6.55027 15.4342 6.43306 15.3169C6.31585 15.1997 6.25 15.0408 6.25 14.875C6.25 14.7092 6.31585 14.5503 6.43306 14.4331C6.55027 14.3158 6.70924 14.25 6.875 14.25H10.625C10.7908 14.25 10.9497 14.3158 11.0669 14.4331C11.1842 14.5503 11.25 14.7092 11.25 14.875C11.25 15.0408 11.1842 15.1997 11.0669 15.3169C10.9497 15.4342 10.7908 15.5 10.625 15.5ZM10.625 13H6.875C6.70924 13 6.55027 12.9342 6.43306 12.8169C6.31585 12.6997 6.25 12.5408 6.25 12.375C6.25 12.2092 6.31585 12.0503 6.43306 11.9331C6.55027 11.8158 6.70924 11.75 6.875 11.75H10.625C10.7908 11.75 10.9497 11.8158 11.0669 11.9331C11.1842 12.0503 11.25 12.2092 11.25 12.375C11.25 12.5408 11.1842 12.6997 11.0669 12.8169C10.9497 12.9342 10.7908 13 10.625 13ZM15.625 14.875H14.375V8.625C14.3751 8.5429 14.359 8.46159 14.3276 8.38572C14.2962 8.30985 14.2502 8.2409 14.1922 8.18281L11.0672 5.05781C11.0091 4.99979 10.9402 4.95378 10.8643 4.92241C10.7884 4.89105 10.7071 4.87494 10.625 4.875H6.875V3.625H12.8664L15.625 6.38359V14.875Z"
                            fill="#675B5E"
                          />
                        </svg>
                      }
                      isSolid={false}
                    />
                  </div>
                </div>
              </div>
              <div>
                <TableContainer component={Paper} className={classes.divTable}>
                  <Table
                    sx={{
                      "& .MuiTableContainer-root": {
                        boxShadow: "0px 0px 0px",
                      },
                    }}
                    aria-label="custom styled table"
                  >
                    <TableBody>
                      {dataStaging.map((row) => (
                        <StyledTableRow key={row.label}>
                          <StyledTableCell className={classes.cellKey}>
                            <Typography className={classes.textKey}>
                              {row.label}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell className={classes.cellKey}>
                            <Typography className={classes.textValue}>
                              {row.value}
                            </Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div>
                <Typography className={classes.textReqProd}>
                  {t(strings.textPleaseClickToReqProd)}
                </Typography>
                <div style={{ width: "max-content" }}>
                  <Buttons
                    loadingSubmit={false}
                    label={
                      merchantVerif?.staging === "DONE" &&
                      merchantVerif?.production === "RESUBMIT REQUEST"
                        ? "Resubmit Production"
                        : merchantVerif?.staging === "DONE" &&
                          merchantVerif?.production !== null
                        ? t(strings.continue)
                        : t(strings.textReqProd)
                    }
                    handleDisabledButton={() => false}
                    onSubmitData={submitRequestProd}
                    rightIcon={null}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <CardInformation
                header={t(strings.WebStgCredenCreation)}
                text={t(strings.textCreationWebStginProgress)}
                status={"waiting"}
              />
              <Buttons
                loadingSubmit={false}
                label={t(strings.textCheckProses)}
                handleDisabledButton={() => false}
                onSubmitData={() =>
                  getVerificationStatus(
                    setLoading,
                    setIsStaging,
                    setActiveStepOutlined,
                    setActiveSectionNumber,
                    t,
                    strings,
                    showSnackbar,
                    setStatusStaging,
                    detailParam,
                    setIsProd,
                    setStatusProd
                  )
                }
                rightIcon={null}
              />
              <ContactSection
                t={t}
                title={t(strings.textHaveAQuestion)}
                subtitle={t(strings.textContactViaWaandEmail)}
              />
            </>
          )}
        </div>
      </div>
    </>
  ) : (
    <LoadingState />
  );
};

export default WebStaging;
