import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import classes from "../Style/general.module.css";

const FormCheckBox = ({
  label,
  icon,
  checked,
  onChange,
  name,
  additionalName = "",
  additionalType = "",
  columnsPerRow,
  borderedIcon = false,
  additionalContent,
  disabled = false,
  allItem = {},
}) => {
  return (
    <FormControlLabel
      className={
        columnsPerRow > 1
          ? classes.divFormCheckbox
          : classes.divFormCheckboxNoImage
      }
      control={
        <Checkbox
          checked={checked}
          disabled={disabled}
          onChange={(e) =>
            onChange(
              e.target.checked,
              name,
              additionalName,
              additionalType,
              allItem
            )
          }
          name={name || ""}
          sx={{
            color: "#CFDFEC",
            "&.Mui-checked": {
              color: "#FDCD07",
            },
            "&.Mui-checked:hover": {
              backgroundColor: "rgba(0, 128, 0, 0.08)",
            },
            "&:hover": {
              backgroundColor: "#FFF",
            },
          }}
        />
      }
      label={
        <div className={classes.divImageLabelChannel}>
          {icon && (
            <div
              className={
                borderedIcon
                  ? classes.borderedImageChannel
                  : classes.imageChannel
              }
            >
              <img
                src={icon}
                alt={label}
                className={classes.imageChannelCover}
              />
            </div>
          )}
          <div className={classes.divAdditionalChannel}>
            <Typography
              className={
                disabled
                  ? classes.textGray14SemiBold600
                  : classes.textSemiBoldDarker
              }
            >
              {label || ""}
            </Typography>
            {additionalContent || null}
          </div>
        </div>
      }
    />
  );
};

export default FormCheckBox;
