import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const getMerchantCriteria = (
  t,
  strings,
  setCriteriaList,
  setLoadingMerchant
) => {
  setLoadingMerchant(true);

  const dataCriteria = [
    {
      id: "UMI",
      decription: t(strings.textDescriptionUMI),
      note: `* ${t(strings.textNotesCriteria)}`,
    },
    {
      id: "UKE",
      decription: t(strings.textDescriptionUKE),
      note: `* ${t(strings.textNotesCriteria)}`,
    },
    {
      id: "UME",
      decription: t(strings.textDescriptionUME),
      note: `* ${t(strings.textNotesCriteria)}`,
    },
    {
      id: "UBE",
      decription: t(strings.textDescriptionUBE),
      note: "",
    },
  ];

  const headers = headerRequest();
  RequestHttpGateway.get(`mis/master/merchant/criteria?isCleanCache=false`, {
    headers,
  })
    .then((res) => {
      const result = res.data.data;
      let removedRedundantName = [
        ...new Map(result.map((item) => [item.description, item])).values(),
      ];

      let objectResult = removedRedundantName?.map((data) => {
        let filteredData = dataCriteria.filter(
          (criteria) => criteria.id === data.master_code
        )?.[0];

        return {
          type: data.master_type,
          label: data.description,
          id: data.master_code,
          description: filteredData?.decription || "",
          note: filteredData?.note || "",
        };
      });
      setCriteriaList(objectResult);

      setLoadingMerchant(false);
    })
    .catch((err) => {})
    .finally(() => {});
};

export default getMerchantCriteria;
