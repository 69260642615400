import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const getCountryList = (
  t,
  strings,
  setCountryList,
  setLoadingMerchant,
  showSnackbar
) => {
  setLoadingMerchant(true);
  const headers = headerRequest();
  RequestHttpGateway.get(`mis/master/country?isCleanCache=false`, {
    headers,
  })
    .then((res) => {
      let result = res.data.data;
      let removedRedundantName = [
        ...new Map(result.map((item) => [item.country_name, item])).values(),
      ];
      let objectResult = removedRedundantName.map((data) => {
        return {
          label: data.country_name,
          id: data.country_code,
        };
      });
      setCountryList(objectResult);
    })
    .catch((err) => {
      showSnackbar(
        err.response.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    })
    .finally(() => {
      setLoadingMerchant(false);
    });
};

export default getCountryList;
