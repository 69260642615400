import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";

const getBucketStorageGcp = (mediaId, setLogo) => {
  RequestHttpGateway.get(
    `/bucket-storage-gcp/${mediaId}`,
    {},
    {
      headers: {},
    }
  )
    .then((res) => {
      setLogo(res.data.data.base64File);
    })
    .catch((_err) => {
      console.log(_err);
    });
};

export default getBucketStorageGcp;
