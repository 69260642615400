const formatNameUnderscore = (name) => {
  const removeUnderscore = name.replace(/_/g, " ");
  const replacedName = removeUnderscore.replace(
    /(^\w{1})|(\s+\w{1})/g,
    (letter) => letter.toUpperCase()
  );
  return replacedName;
};

export default formatNameUnderscore;
