const listDisbursementDetails = [
  {
    name: "Disbursement API",
    label: "API Direct Integration",
    checked: true,
  },
  // { name: "Disbursement Portal", label: "Disbursement Portal", checked: false },
  // { name: "Disbursement API", label: "Disbursement API", checked: false },
];

export default listDisbursementDetails;
