import { Button } from "@mui/material";
import classes from "../Style/general.module.css";

const IconButtons = ({
  loadingSubmit,
  icon,
  isSolid = true,
  iconVersion = false,
  disabled = false,
  handleDisabledButton,
  onSubmitData,
}) => {
  const isDisabled = disabled || loadingSubmit || handleDisabledButton();

  const buttonClass = isDisabled
    ? `${classes.buttonIconInactive} ${classes.textBold600Gray}`
    : isSolid
    ? `${classes.buttonSubmit} ${classes.textBold600Darker}`
    : iconVersion
    ? `${classes.buttonIcon}`
    : `${classes.buttonSubmitOutlined} ${classes.textBold600H5}`;

  return (
    <Button
      fullWidth
      disabled={isDisabled}
      variant={"contained"}
      className={buttonClass}
      onClick={(e) => onSubmitData(e)}
    >
      {icon}
    </Button>
  );
};

export default IconButtons;
