import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";
import PreviewMerchant from "./temporaryPreview";

const getMerchantPreview = (
  t,
  string,
  setData,
  setLoading,
  setPdfContent,
  userDataLogin
) => {
  const headers = headerRequest();
  setLoading(true);
  RequestHttpGateway.get(`/merchant/preview`, { headers })
    .then((res) => {
      setLoading(false);
      const results = res.data.data;
      PreviewMerchant(
        results,
        t,
        string,
        setData,
        setPdfContent,
        userDataLogin
      );
    })
    .catch((_err) => {
      setLoading(false);
    });
};

export default getMerchantPreview;
