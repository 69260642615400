import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const getChannel = (setChannelList, setLoadingChannel) => {
  setLoadingChannel(true);
  const headers = headerRequest();
  RequestHttpGateway.get(`mis/channels?channelType=&acquiring`, {
    headers,
  })
    .then((res) => {
      setChannelList(res.data.data);
    })
    .catch((err) => {})
    .finally(() => {
      setLoadingChannel(false);
    });
};

export default getChannel;
