import RequestHttpGateway from "./RequestHttpGateway";
import headerRequest from "./headerRequest";

const getBankList = (searchValue, setBankList, setLoadingMerchant) => {
  setLoadingMerchant(true);
  const headers = headerRequest();
  RequestHttpGateway.get(`mis/master/banks?bankName=${searchValue}`, {
    headers,
  })
    .then((res) => {
      setBankList(res.data.data);
      setLoadingMerchant(false);
    })
    .catch((err) => {})
    .finally(() => {});
};

export default getBankList;
