import { useTranslation } from "react-i18next";
import Textfields from "../../General/Component/Textfields";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import classes from "../RegistMerchant.module.css";
import { useEffect, useState } from "react";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import useSnackbar from "../../General/Helper/useSnackbar";
import Buttons from "../../General/Component/Buttons";
import RightIcon from "../../General/Icon/RightIcon";
import convertFileToBase64 from "../../General/Helper/convertFileToBase64";
import onSubmitMerchantInfo from "../Helper/onSubmitMerchantInfo";
import { useDispatch, useSelector } from "react-redux";
import getMerchantDetail from "../Helper/getMerchantDetail";
import getMerchantFile from "../Helper/getMerchantFile";
import { Typography } from "@mui/material";
import {
  renderBusinessOwnerField,
  renderCitizen,
  renderDirectorField,
  renderField,
  renderPlaceInfo,
} from "../Helper/renderMerchantInfo";
import objectMerchantCorporate from "../Constant/objectMerchantCorporate";
import objectMerchantNonCorporate from "../Constant/objectMerchantNonCorporate";
import {
  isValidAddress,
  isValidCity,
  isValidIDCard,
  isValidNPWP,
  isValidName,
  isValidNumber,
  isValidPassport,
  isValidPostCode,
  isValidURL,
} from "../../General/Helper/handleValidation";
import { LoadingState } from "../../General/Component/LoadingComp";
import getMROList from "../Helper/getMROList";
import InfoDataCorrection from "./InfoDataCorrection";
import { fetchMerchantVerification } from "../../Store/userSlice";
import getCountryList from "../Helper/getCountryList";

const FormRegistrationInfo = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
  errorVerificationData,
  setErrorVerificationData,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const dispatch = useDispatch();
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const [merchantDetail, setMerchantDetail] = useState(null);
  const [mroList, setMROList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [loadingMerchant, setLoadingMerchant] = useState(true);
  const [isWNA, setIsWNA] = useState(false);

  const isCorporate =
    userDataLogin?.business_type === "Corporate" ? true : false;
  const merchantVerif = useSelector(
    (state) => state.user_data.verificationStatus
  );
  const [formData, setFormData] = useState({
    merchantName: "",
    countryName: "",
    provinceName: "",
    cityName: "",
    postCode: "",
    urlWeb: "",
    npwpNumberCompany: "",
    citizenship: "",
    ktpNumberDirector: "",
    passportDirector: "",
    address: "",
    businessTime: "",
    employeeNumber: "",
    referralName: "",
    directorName: "",
    guaratorName: "",
    idCardGuarantor: "",
    directorAddress: "",
    filePaymentFlow: { base64: "", fileName: "" },
    fileMerchantLogo: { base64: "", fileName: "" },
    loadingSubmit: false,
  });

  useEffect(() => {
    getMROList(t, strings, setMROList, setLoadingMerchant, showSnackbar);
    getCountryList(
      t,
      strings,
      setCountryList,
      setLoadingMerchant,
      showSnackbar
    );
    getMerchantDetail(
      t,
      strings,
      activeSectionNumber,
      activeStepOutlined,
      setMerchantDetail,
      setLoadingMerchant,
      showSnackbar
    );
    if (merchantVerif) {
      setErrorVerificationData(merchantVerif?.reason);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeSectionNumber,
    activeStepOutlined,
    showSnackbar,
    t,
    userDataLogin,
    merchantVerif,
  ]);

  useEffect(() => {
    dispatch(fetchMerchantVerification());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (merchantDetail) {
      const {
        merchantName,
        urlDomain,
        npwp,
        directorCitizenship,
        directorNik,
        directorPassport,
        address,
        operationYears,
        totalEmployees,
        directorName,
        directorPicName,
        directorPicNik,
        directorAddress,
        merchantMro,
        countryCode,
        city,
        state,
        postcode,
        merchantDocuments,
        merchantLogo,
      } = merchantDetail;

      setFormData((prev) => ({
        ...prev,
        merchantName: merchantName,
        urlWeb: urlDomain,
        npwpNumberCompany: npwp,
        citizenship: directorCitizenship,
        ktpNumberDirector: directorNik,
        passportDirector: directorPassport,
        address: address,
        businessTime: operationYears,
        employeeNumber: totalEmployees,
        directorName: directorName,
        guaratorName: directorPicName,
        idCardGuarantor: directorPicNik,
        directorAddress: directorAddress,
        referralName: merchantMro?.merchantMroUsername || null,
        countryName: countryCode,
        cityName: city,
        provinceName: state,
        postCode: postcode,
      }));

      if (directorCitizenship === "Foreign") {
        setIsWNA(true);
      }

      const merchantDocs = merchantDocuments?.filter((data) =>
        data.documentUrl.toLowerCase().includes("paymentflow")
      )[0];

      if (merchantDocs?.documentUrl) {
        getMerchantFile(
          t,
          strings,
          "filePaymentFlow",
          "pdf",
          merchantDocs.documentUrl,
          merchantDocs.fileName,
          setFormData,
          showSnackbar
        );
      }

      if (merchantDetail?.merchantLogo) {
        getMerchantFile(
          t,
          strings,
          "fileMerchantLogo",
          "png",
          merchantLogo,
          "",
          setFormData,
          showSnackbar
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetail, showSnackbar, t]);

  const handleChange = async (e, specificName) => {
    const { target } = e;

    if (target) {
      const { name, value, type } = target;
      if (type === "file") {
        const file = e.target.files[0];
        if (file) {
          try {
            const base64File = await convertFileToBase64(file);
            setFormData((prev) => ({
              ...prev,
              [name]: { base64: base64File, fileName: file.name },
            }));
          } catch (error) {}
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
        if (name === "citizenship") {
          if (value === "Foreign") {
            setIsWNA(true);
          } else {
            setIsWNA(false);
          }
        }
      }
    } else if (specificName) {
      setFormData((prev) => ({ ...prev, [specificName]: e }));
    }
  };

  useEffect(() => {
    handleDisabledButton();
    calculateProgressValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, merchantDetail, isWNA]);

  const handleDisabledButton = () => {
    const {
      loadingSubmit,
      merchantName,
      countryName,
      provinceName,
      cityName,
      postCode,
      businessTime,
      employeeNumber,
      fileMerchantLogo,
      filePaymentFlow,
      npwpNumberCompany,
      urlWeb,
      address,
      citizenship,
      directorName,
      directorAddress,
      ktpNumberDirector,
      passportDirector,
      guaratorName,
      idCardGuarantor,
    } = formData;

    const isFormIncomplete =
      loadingSubmit ||
      !merchantName ||
      !isValidName(merchantName) ||
      !countryName ||
      !provinceName ||
      !isValidName(provinceName) ||
      !cityName ||
      !isValidCity(cityName) ||
      !postCode ||
      !isValidPostCode(postCode) ||
      !urlWeb ||
      !isValidURL(urlWeb) ||
      !npwpNumberCompany ||
      !isValidNPWP(npwpNumberCompany) ||
      !fileMerchantLogo?.base64 ||
      !filePaymentFlow?.base64;

    const isWNIInfoInvalid =
      !ktpNumberDirector || !isValidIDCard(ktpNumberDirector);

    const isCorporateInfoWNAInvalid =
      !businessTime ||
      !isValidNumber(businessTime) ||
      !employeeNumber ||
      !directorName ||
      !isValidName(directorName) ||
      !passportDirector ||
      !isValidPassport(passportDirector) ||
      !guaratorName ||
      !isValidName(guaratorName) ||
      !idCardGuarantor ||
      !isValidIDCard(idCardGuarantor) ||
      !address ||
      !isValidAddress(address) ||
      !citizenship;

    const isCorporateInfoWNIInvalid =
      !businessTime ||
      !isValidNumber(businessTime) ||
      !employeeNumber ||
      isWNIInfoInvalid ||
      !directorName ||
      !isValidName(directorName) ||
      !directorAddress ||
      !isValidAddress(directorAddress) ||
      !address ||
      !isValidAddress(address) ||
      !citizenship;

    const nonCorporateInvalid =
      !directorName ||
      !isValidName(directorName) ||
      !directorAddress ||
      !isValidAddress(directorAddress);

    if (isCorporate) {
      if (isWNA) {
        return isFormIncomplete || isCorporateInfoWNAInvalid;
      } else {
        return isFormIncomplete || isCorporateInfoWNIInvalid;
      }
    } else {
      return isFormIncomplete || isWNIInfoInvalid || nonCorporateInvalid;
    }
  };

  const calculateProgressValue = () => {
    const {
      merchantName,
      countryName,
      provinceName,
      cityName,
      postCode,
      businessTime,
      employeeNumber,
      fileMerchantLogo,
      filePaymentFlow,
      npwpNumberCompany,
      urlWeb,
      address,
      citizenship,
      directorName,
      referralName,
      directorAddress,
      ktpNumberDirector,
      passportDirector,
      guaratorName,
      idCardGuarantor,
    } = formData;

    let progressConditions = [
      merchantName,
      countryName,
      provinceName,
      cityName,
      postCode,
      businessTime,
      employeeNumber,
      fileMerchantLogo?.base64,
      filePaymentFlow?.base64,
      npwpNumberCompany,
      urlWeb,
    ];

    if (isCorporate) {
      progressConditions.push(
        address,
        citizenship,
        directorName,
        directorAddress,
        referralName
      );

      if (!isWNA) {
        progressConditions.push(ktpNumberDirector);
      } else if (isWNA) {
        progressConditions.push(
          passportDirector,
          guaratorName,
          idCardGuarantor
        );
      }
    } else {
      progressConditions.push(ktpNumberDirector);
    }

    const validProgressCount = progressConditions.filter(Boolean).length;
    const progressNumber =
      (validProgressCount / progressConditions.length) * 20;
    setProgressData((prev) => ({
      ...prev,
      section1: Math.ceil(progressNumber),
    }));
  };

  const submitMerchantInfo = () => {
    const {
      merchantName,
      countryName,
      provinceName,
      cityName,
      postCode,
      businessTime,
      employeeNumber,
      fileMerchantLogo,
      filePaymentFlow,
      npwpNumberCompany,
      urlWeb,
      address,
      citizenship,
      directorName,
      referralName,
      directorAddress,
      ktpNumberDirector,
      passportDirector,
      guaratorName,
      idCardGuarantor,
    } = formData;

    const payload = {
      businessType: userDataLogin?.business_type,
      section: activeSectionNumber + 1,
      merchantName: merchantName,
      countryCode: countryName,
      state: provinceName,
      city: cityName,
      postcode: postCode,
      urlDomain: urlWeb,
      npwp: npwpNumberCompany,
      directorCitizenship: citizenship || "",
      directorNik: ktpNumberDirector || "",
      directorPassport: passportDirector || "",
      directorName: directorName || "",
      directorAddress: directorAddress || "",
      directorPicName: guaratorName || "",
      directorPicNik: idCardGuarantor || "",
      address: address || "",
      operationYears: businessTime || "",
      totalEmployees: employeeNumber || "",
      merchantPaymentFlowFileName: filePaymentFlow?.fileName || "",
      merchantPaymentFlowFile: filePaymentFlow?.base64 || "",
      merchantLogoFileName: fileMerchantLogo.fileName || "",
      merchantLogoFile: fileMerchantLogo.base64 || "",
      registrationCompletion: progressData.section1,
      totalCompletion: 20,
    };

    let mroData = mroList
      .filter((data) => data.id === referralName)
      .map((data) => ({
        mroUsername: data.id,
        mroName: data.label,
        mroMisId: data.keyMRO,
      }))[0];
    payload["merchantMro"] = mroData;

    onSubmitMerchantInfo(
      t,
      strings,
      payload,
      activeSectionNumber,
      setActiveSectionNumber,
      activeStepOutlined,
      setActiveStepOutlined,
      setFormData,
      showSnackbar
    );
  };

  const handleCloseFile = (e, name, label) => {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById(label).value = "";

    setFormData((prev) => ({
      ...prev,
      [name]: {
        base64: "",
        fileName: "",
      },
    }));
  };

  return !loadingMerchant ? (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />

      <div className={classes.divFormStepper}>
        <InfoDataCorrection
          errorVerificationData={errorVerificationData}
          t={t}
          strings={strings}
          classes={classes}
        />
        {isCorporate ? (
          <>
            {objectMerchantCorporate(t, strings)
              .slice(0, 1)
              .map((field, index) => {
                return renderField(
                  t,
                  strings,
                  field,
                  index,
                  formData,
                  handleChange,
                  showSnackbar,
                  handleCloseFile,
                  mroList
                );
              })}
            <div className={classes.divWrapFourColumn}>
              {objectMerchantCorporate(t, strings)
                .slice(1, 5)
                .map((field, index) => {
                  return renderPlaceInfo(
                    t,
                    strings,
                    classes,
                    field,
                    index,
                    formData,
                    handleChange,
                    showSnackbar,
                    handleCloseFile,
                    countryList
                  );
                })}
            </div>

            {objectMerchantCorporate(t, strings)
              .slice(5, 13)
              .map((field, index) => {
                return renderField(
                  t,
                  strings,
                  field,
                  index,
                  formData,
                  handleChange,
                  showSnackbar,
                  handleCloseFile,
                  mroList
                );
              })}

            <div className={classes.divLine} />
            <div className={classes.groupWithSubtileSmall}>
              <div>
                <Typography className={classes.textSuperBold18}>
                  {t(strings.textDirectorInformation)}
                </Typography>
                <Typography className={classes.textRegularDarkGray14}>
                  {t(strings.textCompleteDirectorInfo)}
                </Typography>
              </div>
            </div>

            {objectMerchantCorporate(t, strings)
              .slice(13, 14)
              .map((field, index) => {
                return renderCitizen(
                  t,
                  strings,
                  classes,
                  field,
                  index,
                  formData,
                  handleChange,
                  showSnackbar
                );
              })}

            <div className={classes.divWrapFourColumn}>
              {objectMerchantCorporate(t, strings)
                .slice(10, 20)
                .map((field, index) => {
                  return renderDirectorField(
                    t,
                    strings,
                    classes,
                    field,
                    index,
                    formData,
                    handleChange,
                    showSnackbar
                  );
                })}
            </div>

            {objectMerchantCorporate(t, strings).map((field, index) => {
              return field.name === "directorAddress" ? (
                <Textfields
                  key={index}
                  isHideLabel={true}
                  placeholder={field.placeholder}
                  label={field.label}
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  isValidate={true}
                  isOptional={false}
                  isAny={field.isAny || false}
                />
              ) : null;
            })}
            <Buttons
              loadingSubmit={formData.loadingSubmit}
              label={t(strings.textNext)}
              handleDisabledButton={handleDisabledButton}
              onSubmitData={submitMerchantInfo}
              rightIcon={<RightIcon />}
            />
          </>
        ) : (
          <>
            {objectMerchantNonCorporate(t, strings)
              .slice(0, 1)
              .map((field, index) => {
                return renderField(
                  t,
                  strings,
                  field,
                  index,
                  formData,
                  handleChange,
                  showSnackbar,
                  handleCloseFile,
                  mroList
                );
              })}

            <div className={classes.divWrapFourColumn}>
              {objectMerchantNonCorporate(t, strings)
                .slice(1, 5)
                .map((field, index) => {
                  return renderPlaceInfo(
                    t,
                    strings,
                    classes,
                    field,
                    index,
                    formData,
                    handleChange,
                    showSnackbar,
                    handleCloseFile,
                    countryList
                  );
                })}
            </div>

            {objectMerchantNonCorporate(t, strings)
              .slice(5, 10)
              .map((field, index) => {
                return renderField(
                  t,
                  strings,
                  field,
                  index,
                  formData,
                  handleChange,
                  showSnackbar,
                  handleCloseFile,
                  mroList
                );
              })}
            <div className={classes.divLine} />
            <div className={classes.groupWithSubtileSmall}>
              <div>
                <Typography className={classes.textSuperBold18}>
                  {t(strings.textBusinessOwnerInformation)}
                </Typography>
                <Typography className={classes.textRegularDarkGray14}>
                  {t(strings.textCompleteBusinessOwnerInfo)}
                </Typography>
              </div>
            </div>

            <div className={classes.divWrapFourColumn}>
              {objectMerchantNonCorporate(t, strings)
                .slice(10, 12)
                .map((field, index) => {
                  return renderBusinessOwnerField(
                    classes,
                    field,
                    index,
                    formData,
                    handleChange
                  );
                })}
            </div>

            {objectMerchantNonCorporate(t, strings)
              .slice(12, 15)
              .map((field, index) => {
                return renderField(
                  t,
                  strings,
                  field,
                  index,
                  formData,
                  handleChange,
                  showSnackbar,
                  handleCloseFile,
                  mroList
                );
              })}
            <Buttons
              loadingSubmit={formData.loadingSubmit}
              label={t(strings.textNext)}
              handleDisabledButton={handleDisabledButton}
              onSubmitData={submitMerchantInfo}
              rightIcon={<RightIcon />}
            />
          </>
        )}
      </div>
    </>
  ) : (
    <LoadingState />
  );
};

export default FormRegistrationInfo;
