const PreviewMerchant = (
  response,
  t,
  string,
  setData,
  setPdfContent,
  userDataLogin
) => {
  const list = [];
  const page1 = [];
  const page2 = [];
  const page3 = [];
  const page4 = [];
  const defaultLogo =
    "https://res.cloudinary.com/pastisah-id/image/upload/v1731662683/credit-card-fill_qrhnhw.png";
  if (response.length > 0) {
    response.forEach((element) => {
      const data = element.data;
      if (element.section === 1 && element.step === 1) {
        const tempPush = {
          summary: t(string.textRegistrationInformation),
          pannel: "pannel1",
          expanded: true,
          details: [
            {
              section: 1,
              subtitle: "",
              subtext: "",
              list: [
                {
                  label: t(string.textMerchantName),
                  mandatory: true,
                  value: data.merchantName ? data.merchantName : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textMerchantAddress),
                  mandatory: true,
                  value: data.address ? data.address : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textURLWeb),
                  mandatory: true,
                  value: data.urlDomain ? data.urlDomain : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textCompanyNPWP),
                  mandatory: true,
                  value: data.npwp ? data.npwp : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textBusinessLength),
                  mandatory: true,
                  value: data.operationYears ? data.operationYears : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textNumberEmployee),
                  mandatory: true,
                  value: data.totalEmployees ? data.totalEmployees : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textPaymentProcessFlow),
                  mandatory: true,
                  value: data.merchantDocuments[0]?.fileName
                    ? data.merchantDocuments[0]?.fileName
                    : "-",
                  disable: true,
                  type: "file",
                  isPageBreak: false,
                },
                {
                  label: t(string.textMerchantLogo),
                  mandatory: true,
                  value: data.merchantLogo ? data.merchantLogo : "-",
                  disable: true,
                  type: "file",
                  isPageBreak: false,
                },
                {
                  label: t(string.textMROName),
                  mandatory: false,
                  value: data?.merchantMro?.merchantMroName
                    ? data?.merchantMro?.merchantMroName
                    : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
              ],
            },
            {
              section: 2,
              subtitle: "",
              subtext: t(string.textDirectorInfo),
              list: [
                {
                  label: t(string.textCitizenship),
                  mandatory: true,
                  value: data.directorCitizenship
                    ? data.directorCitizenship
                    : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textDirectorName),
                  mandatory: true,
                  value: data.directorName ? data.directorName : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textIDDirector),
                  mandatory: true,
                  value:
                    data.directorCitizenship === "Indonesian"
                      ? data.directorNik
                      : data.directorPassport,
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textDirectorAddress),
                  mandatory: true,
                  value: data.directorAddress ? data.directorAddress : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
              ],
            },
          ],
        };
        list.push(tempPush);
        page1.push(tempPush);
      } else if (element.section === 2 && element.step === 1) {
        const temp = {
          summary: t(string.textPICInfo),
          pannel: "pannel2",
          expanded: false,
          details: [],
        };
        data.merchantPersonInCharges?.forEach((val) => {
          if (val.picType === "Business") {
            temp.details.push({
              section: 1,
              subtitle: `${t(string.textBusiness)}`,
              subtext: "",
              list: [
                {
                  label: t(string.textEmployeeName),
                  mandatory: true,
                  value: val.picName,
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textEmployeeTitle),
                  mandatory: true,
                  value: val.position,
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textEmployeePhoneNumber),
                  mandatory: true,
                  value: val.phone,
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textEmployeeEmail),
                  mandatory: true,
                  value: val.email,
                  disable: true,
                  type: "text",
                  isPageBreak: true,
                },
              ],
            });
          } else if (val.picType === "Finance") {
            temp.details.push({
              section: 2,
              subtitle: `${t(string.textFinance)}`,
              subtext: "",
              list: [
                {
                  label: t(string.textEmployeeName),
                  mandatory: true,
                  value: val.picName,
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textEmployeeTitle),
                  mandatory: true,
                  value: val.position,
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textEmployeePhoneNumber),
                  mandatory: true,
                  value: val.phone,
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textEmployeeEmail),
                  mandatory: true,
                  value: val.email,
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
              ],
            });
          } else if (val.picType === "Technical") {
            temp.details.push({
              section: 3,
              subtitle: `${t(string.textTechnical)}`,
              subtext: "",
              list: [
                {
                  label: t(string.textEmployeeName),
                  mandatory: true,
                  value: val.picName,
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textEmployeeTitle),
                  mandatory: true,
                  value: val.position,
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textEmployeePhoneNumber),
                  mandatory: true,
                  value: val.phone,
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textEmployeeEmail),
                  mandatory: true,
                  value: val.email,
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
              ],
            });
          }
        });
        list.push(temp);
        const forPage1 = {
          summary: t(string.textPICInfo),
          pannel: "pannel2",
          expanded: false,
          details: temp.details.slice(0, 1),
        };
        const forPage2 = {
          summary: "",
          pannel: "pannel2",
          expanded: false,
          details: temp.details.slice(-2),
        };
        page1.push(forPage1);
        page2.push(forPage2);
      } else if (element.section === 3 && element.step === 1) {
        const tempPush = {
          summary: t(string.textBusinessInformation),
          pannel: "pannel3",
          expanded: false,
          details: [
            {
              section: 1,
              subtitle: "",
              subtext: "",
              list: [
                {
                  label: t(string.textProductName),
                  mandatory: true,
                  value: data.merchantProduct ? data.merchantProduct : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textMarketTarget),
                  mandatory: true,
                  value: data.targetMarket ? data.targetMarket : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textIndustryName),
                  mandatory: true,
                  value: data.industry ? data.industry : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textTypeCorporateTax),
                  mandatory: true,
                  value: data.taxType ? data.taxType : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textCurrentMonthlySales),
                  mandatory: true,
                  value: data.currentMonthlySales
                    ? data.currentMonthlySales
                    : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textAverageBilling),
                  mandatory: true,
                  value: data.estimatedAverageBillingAccount
                    ? data.estimatedAverageBillingAccount
                    : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textMonthlySalesTurnover),
                  mandatory: true,
                  value: data.estimatedMonthlySalesTurnover
                    ? data.estimatedMonthlySalesTurnover
                    : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
              ],
            },
            {
              section: 2,
              subtitle: t(string.textBankAccountInformation),
              subtext: "",
              list: [
                {
                  label: t(string.textCurrency),
                  mandatory: true,
                  value: data.merchantBank.accountCurrency
                    ? data.merchantBank.accountCurrency
                    : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textBankName),
                  mandatory: true,
                  value: data.merchantBank.bank ? data.merchantBank.bank : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textBankBranch),
                  mandatory: true,
                  value: data.merchantBank.branch
                    ? data.merchantBank.branch
                    : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textBankAccountName),
                  mandatory: true,
                  value: data.merchantBank.accountName
                    ? data.merchantBank.accountName
                    : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
                {
                  label: t(string.textBankNumber),
                  mandatory: true,
                  value: data.merchantBank.accountNumber
                    ? data.merchantBank.accountNumber
                    : "-",
                  disable: true,
                  type: "text",
                  isPageBreak: false,
                },
              ],
            },
          ],
        };
        list.push(tempPush);
        page2.push(tempPush);
      } else if (element.section === 4 && element.step === 1) {
        const temp = {
          summary: t(string.textServicesAndFees),
          pannel: "pannel4",
          expanded: false,
          details: [],
        };

        let tempArray = [];
        data.merchantServiceDetails?.forEach((item) => {
          tempArray.push(item.serviceDetailName);
        });
        let text = tempArray.join(", ");
        const tempVA = {
          section: 3,
          subtitle: "",
          subtext: "",
          list: [],
        };
        const tempWallet = {
          section: 4,
          subtitle: "",
          subtext: "",
          list: [],
        };
        const tempOTC = {
          section: 5,
          subtitle: "",
          subtext: "",
          list: [],
        };
        const tempDirectDebit = {
          section: 6,
          subtitle: "",
          subtext: "",
          list: [],
        };
        temp.details.push({
          section: 1,
          subtitle: "",
          subtext: "",
          list: [
            {
              label: t(string.textTypePaymentGateway),
              mandatory: true,
              value: data.serviceCategory,
              disable: true,
              type: "text",
            },
            {
              label: t(string.textIntegrationService),
              mandatory: true,
              value: text,
              disable: true,
              type: "text",
            },
          ],
        });
        data.merchantChannels?.forEach((item) => {
          if (item.merchantChannelType === "CREDIT") {
            let tempArray = [];
            let tempInstallment = [];
            for (let i = 0; i < item.channelInstallment.length; i++) {
              tempInstallment.push(
                `${item.channelInstallment[i]} ${
                  i === 0 ? t(string.textMonth) : t(string.textMonths)
                }`
              );
            }
            item.channelMode?.forEach((el) => {
              if (el === "installment") {
                tempArray.push(`${el} (${tempInstallment.join(", ")})`);
              } else {
                tempArray.push(el);
              }
            });
            let text = tempArray.join(", ");
            temp.details.push({
              section: 2,
              subtitle: t(string.textSelectedPaymentMethod),
              subtext: t(string.textCreditCard),
              list: [
                {
                  label: "",
                  mandatory: true,
                  value: item.acquiring,
                  logo: item.channelLogo ? item.channelLogo : defaultLogo,
                  disable: true,
                  type: "img",
                  opsiPembayaran: text,
                },
              ],
            });
          } else if (item.merchantChannelType === "VIRTUAL_ACCOUNT") {
            let tempArray = [];
            item.channelMode?.forEach((el) => {
              tempArray.push(el);
            });
            let text = tempArray.join(", ");
            tempVA.subtext = t(string.textVA);
            tempVA.list.push({
              label: "",
              mandatory: true,
              value: item.acquiring,
              logo: item.channelLogo ? item.channelLogo : defaultLogo,
              disable: true,
              type: "img",
              opsiPembayaran: text,
            });
          } else if (item.merchantChannelType === "WALLET") {
            let tempArray = [];
            item.channelMode?.forEach((el) => {
              tempArray.push(el);
            });
            let text = tempArray.join(", ");
            tempWallet.subtext = t(string.textWallet);
            tempWallet.list.push({
              label: "",
              mandatory: true,
              value: item.acquiring,
              logo: item.channelLogo ? item.channelLogo : defaultLogo,
              disable: true,
              type: "img",
              opsiPembayaran: text,
            });
          } else if (item.merchantChannelType === "OTC") {
            let tempArray = [];
            item.channelMode?.forEach((el) => {
              tempArray.push(el);
            });
            let text = tempArray.join(", ");
            tempOTC.subtext = t(string.textOTC);
            tempOTC.list.push({
              label: "",
              mandatory: true,
              value: item.acquiring,
              logo: item.channelLogo ? item.channelLogo : defaultLogo,
              disable: true,
              type: "img",
              opsiPembayaran: text,
            });
          } else if (item.merchantChannelType === "DIRECT_DEBIT") {
            let tempArray = [];
            item.channelMode?.forEach((el) => {
              tempArray.push(el);
            });
            let text = tempArray.join(", ");
            tempDirectDebit.subtext = t(string.textOTC);
            tempDirectDebit.list.push({
              label: "",
              mandatory: true,
              value: item.acquiring,
              logo: item.channelLogo ? item.channelLogo : defaultLogo,
              disable: true,
              type: "img",
              opsiPembayaran: text,
            });
          }
        });
        temp.details.push(tempVA);
        temp.details.push(tempWallet);
        temp.details.push(tempOTC);
        temp.details.push(tempDirectDebit);
        list.push(temp);
        page3.push(temp);
      } else if (element.section === 1 && element.step === 2) {
        const tempDoc = {
          summary: t(string.textSupportingDocuments),
          pannel: "pannel5",
          expanded: false,
          details: [
            {
              section: 1,
              subtitle: "",
              subtext: "",
              list: [],
            },
          ],
        };
        data.merchantDocuments.forEach((item) => {
          if (userDataLogin?.business_type === "Non Corporate") {
            if (item.documentType.code === "directorId") {
              tempDoc.details[0].list.push({
                label: t(string.textIDDirector),
                mandatory: true,
                value: item.fileName,
                disable: true,
                type: "file",
              });
            } else if (item.documentType.code === "accountNumber") {
              tempDoc.details[0].list.push({
                label: t(string.textBankNumber),
                mandatory: true,
                value: item.fileName,
                disable: true,
                type: "file",
              });
            } else if (item.documentType.code === "npwp") {
              tempDoc.details[0].list.push({
                label: "NPWP",
                mandatory: true,
                value: item.fileName,
                disable: true,
                type: "file",
              });
            } else if (item.documentType.code === "nib") {
              tempDoc.details[0].list.push({
                label: t(string.textNIB),
                mandatory: false,
                value: item.fileName,
                disable: true,
                type: "file",
              });
            }
          } else if (userDataLogin?.business_type === "Corporate") {
            if (item.documentType.code === "directorId") {
              tempDoc.details[0].list.push({
                label: t(string.textIDDirector),
                mandatory: item.documentType.isMandatory,
                value: item.fileName,
                disable: true,
                type: "file",
              });
            } else if (item.documentType.code === "accountNumber") {
              tempDoc.details[0].list.push({
                label: t(string.textBankNumber),
                mandatory: item.documentType.isMandatory,
                value: item.fileName,
                disable: true,
                type: "file",
              });
            } else if (item.documentType.code === "npwp") {
              tempDoc.details[0].list.push({
                label: "NPWP",
                mandatory: item.documentType.isMandatory,
                value: item.fileName,
                disable: true,
                type: "file",
              });
            } else if (item.documentType.code === "amendmentDeed") {
              tempDoc.details[0].list.push({
                label: t(string.textDeedEstablishmentAndAmendment),
                mandatory: item.documentType.isMandatory,
                value: item.fileName,
                disable: true,
                type: "file",
              });
            } else if (item.documentType.code === "nib") {
              tempDoc.details[0].list.push({
                label: t(string.textNIB),
                mandatory: item.documentType.isMandatory,
                value: item.fileName,
                disable: true,
                type: "file",
              });
            } else if (item.documentType.code === "ministryPermit") {
              tempDoc.details[0].list.push({
                label: t(string.textMinistryLawAndHumanRightsDecree),
                mandatory: item.documentType.isMandatory,
                value: item.fileName,
                disable: true,
                type: "file",
              });
            } else if (item.documentType.code === "tdpse") {
              tempDoc.details[0].list.push({
                label: t(string.textTDPSE),
                mandatory: item.documentType.isMandatory,
                value: item.fileName,
                disable: true,
                type: "file",
              });
            } else if (item.documentType.code === "companyPermit") {
              tempDoc.details[0].list.push({
                label: t(string.textInstitutionLicence),
                mandatory: item.documentType.isMandatory,
                value: item.fileName,
                disable: true,
                type: "file",
              });
            }
          }
        });
        list.push(tempDoc);
        page4.push(tempDoc);
      }
    });
  }
  setData(list);
  setPdfContent({
    page1: page1,
    page2: page2,
    page3: page3,
    page4: page4,
  });
};

export default PreviewMerchant;
