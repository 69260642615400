import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const onSubmitMerchant = (
  setActiveStepOutlined,
  setActiveSectionNumber,
  setLoadingSubmit,
  showSnackbar,
  t,
  string
) => {
  setLoadingSubmit(true);
  const headers = headerRequest();
  RequestHttpGateway.post(`/merchant/final`, {}, { headers })
    .then((_res) => {
      setLoadingSubmit(false);
      setActiveStepOutlined((prev) => prev + 1);
      setActiveSectionNumber(0);
    })
    .catch((err) => {
      if (
        err?.response?.data?.response_message ===
        "MERCHANT NAME ALREADY EXIST IN MIS"
      ) {
        setActiveStepOutlined(0);
        setActiveSectionNumber(0);
      }
      setLoadingSubmit(false);
      showSnackbar(
        err.response.data.response_message || t(string.textServiceNotAvailable),
        "error"
      );
    });
};

export default onSubmitMerchant;
