import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import SearchIcon from "../../../General/Icon/SearchIcon";
import formatNameUnderscore from "../../../General/Helper/formatNameUnderscore";
import classes from "../../RegistMerchant.module.css";
import FormCheckBox from "../../../General/Component/FormCheckBox";
import CheckBox from "../../../General/Component/CheckBox";

const AutocompleteProvider = ({
  t,
  strings,
  cardName,
  options,
  placeholder,
  handleChange,
  handleChangeMode,
  formDataChannel,
  additionalName = "",
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchValue, setSearchValue] = useState("");

  const handleChangeItem = (e, value) => {
    setSelectedItem(value);
  };
  const additionalContent = () => {
    const refactoredMode = selectedItem?.mode?.map((item) => ({
      name: item,
      label: item,
      checked: false,
    }));

    const selectMode = formDataChannel[cardName]?.find(
      (item) => item.provider.name === selectedItem?.acquiring
    )?.modes;

    let mergedModes = [];
    if (selectMode) {
      mergedModes = refactoredMode
        .map((item) => {
          const matchingMode = selectMode?.find(
            (mode) => mode.name === item.name
          );

          return matchingMode
            ? { ...item, checked: matchingMode.checked }
            : item;
        })
        .concat(
          selectMode?.filter(
            (mode) => !refactoredMode.some((item) => item.name === mode.name)
          )
        );
    } else {
      mergedModes = refactoredMode;
    }

    const isCheckedProvider = formDataChannel[cardName]?.find(
      (item) => item.provider.name === selectedItem?.acquiring
    )?.provider.checked;

    return (
      <div className={classes.divModeProvider}>
        <div className={classes.divInstallment}>
          {mergedModes.length > 0 && isCheckedProvider && (
            <CheckBox
              name={"mode"}
              title={t(strings.textChoosePayment)}
              options={mergedModes}
              columnsPerRow={1}
              additionalName={selectedItem?.acquiring}
              additionalType={cardName}
              handleChange={handleChangeMode}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Autocomplete
        displayempty="true"
        fullWidth
        name={selectedItem?.acquiring || ""}
        options={options}
        getOptionLabel={(option) => formatNameUnderscore(option.acquiring)}
        value={selectedItem || null}
        onChange={handleChangeItem}
        isOptionEqualToValue={(option, value) =>
          option.acquiring === value.acquiring
        }
        renderOption={(props, option) => {
          const { key, ...restProps } = props;
          return (
            <Box
              key={option.acquiring}
              component="li"
              sx={{ display: "flex", alignItems: "center", gap: 2 }}
              {...restProps}
            >
              <div className={classes.divLogoUrl}>
                <img
                  src={option.logoUrl}
                  alt={option.acquiring}
                  className={classes.logoURL}
                />
              </div>
              {option.acquiring}
            </Box>
          );
        }}
        onInputChange={(event, newInputValue) => {
          setSearchValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder || ""}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
            }}
          />
        )}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "1px solid #cfdfec",
              display: "flex",
              padding: "14px 12px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "8px",
              fontSize: "14px",
            },
          },
        }}
      />

      {selectedItem && (
        <div className={classes.boxCardContent}>
          <Typography className={classes.textBold600Darker14}>{`${t(
            strings.textSearchResult
          )} "${searchValue.toUpperCase()}"`}</Typography>
          <Box className={classes.boxCard}>
            <div className={classes.boxCardSection}>
              <FormCheckBox
                label={formatNameUnderscore(selectedItem?.acquiring)}
                icon={
                  selectedItem.logoUrl ||
                  "https://res.cloudinary.com/pastisah-id/image/upload/v1731662683/credit-card-fill_qrhnhw.png"
                }
                checked={
                  formDataChannel[cardName]?.find(
                    (item) => item.provider.name === selectedItem?.acquiring
                  )?.provider.checked || false
                }
                onChange={handleChange}
                name={selectedItem?.acquiring || ""}
                columnsPerRow={1}
                borderedIcon={true}
                additionalContent={additionalContent()}
                additionalName={additionalName}
                additionalType={cardName}
              />
            </div>
          </Box>
        </div>
      )}
    </>
  );
};

export default AutocompleteProvider;
