import { Typography } from "@mui/material";

import classes from "../Style/general.module.css";
import FormCheckBox from "./FormCheckBox";

const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

const CheckBox = ({
  title,
  options,
  subTitle,
  notes,
  additionalName = "",
  additionalType = "",
  handleChange,
  columnsPerRow = 1,
  disabled = false,
}) => {
  const chunkedOptions = chunkArray(options, columnsPerRow);
  return (
    <div className={classes.divFormFieldCheckbox}>
      <div>
        <Typography className={classes.textBold600BlackH4}>
          {title}
          {subTitle && (
            <span
              className={classes.textGrayItalic12}
              style={{ marginLeft: 4 }}
            >
              {subTitle}
            </span>
          )}
        </Typography>
        {notes && (
          <Typography className={classes.textBold600BlackH4}>
            <span className={classes.textGray700Italic12}>{notes}</span>
          </Typography>
        )}
      </div>

      {chunkedOptions.map((row, rowIndex) => (
        <div
          className="checkbox-row"
          key={rowIndex}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {row.map((data, index) => {
            return (
              <FormCheckBox
                key={index}
                checked={data.checked}
                label={data.label}
                columnsPerRow={columnsPerRow}
                name={data.name}
                additionalName={additionalName}
                additionalType={additionalType}
                onChange={handleChange}
                icon={data.icon || null}
                disabled={disabled ? disabled : data.disabled}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default CheckBox;
