import strings from "../Constant/Locales/Strings/GeneralStrings";
import { useTranslation } from "react-i18next";
import HeaderComponent from "../Component/HeaderComponent";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import classes from "../Style/general.module.css";
import AutoCompleteSelect from "../Component/AutocompleteSelect";
import { useEffect, useState } from "react";
import getBankList from "../Helper/getBankList";
import { LoadingState } from "../Component/LoadingComp";

const SwiftCodePage = () => {
  const { t } = useTranslation("General");
  const [bankList, setBankList] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [loadingBankList, setLoadingBankList] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    getBankList("", setBankList, setLoadingBankList);
  }, []);

  const showTableBank = () => {
    return (
      <Table
        aria-label="simple table"
        sx={{ borderColor: "transparent !important", width: "100%" }}
        className={classes.tableContainerSwift}
      >
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderSwift}>
              {t(strings.textBankName)}
            </TableCell>
            <TableCell className={classes.tableHeaderSwift}>
              {t(strings.textSWIFTCode)}
            </TableCell>
          </TableRow>
        </TableHead>
        {searchList?.length > 0 ? (
          <TableBody>
            {searchList.map((row, index) => (
              <TableRow key={index}>
                <TableCell className={classes.tableRowSwift}>
                  {row.label}
                </TableCell>
                <TableCell className={classes.tableRowSwift}>
                  {row.swiftCode || "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} align="center">
                {t(strings.textNoRecordFound)}
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    );
  };

  useEffect(() => {
    if (bankList?.length) {
      const activeBank = bankList
        .filter((data) => data.is_active)
        .map((data) => ({
          id: data.bank_code,
          label: data.bank_name,
          swiftCode: data.swift_code,
        }))
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.id === value.id)
        );

      const filteredData = searchValue
        ? activeBank.filter((data) => data.id === searchValue)
        : activeBank;

      setSearchList(filteredData);
    }
  }, [bankList, searchValue]);

  const handleChangeBank = (searchValue) => {
    setSearchValue(searchValue);
  };

  return (
    <Grid>
      <HeaderComponent />
      <div className={classes.divContainer}>
        <div className={classes.divBankContainer}>
          <Typography
            className={classes.textSuperBold18}
            style={{ textAlign: "left", width: "100%" }}
          >
            {t(strings.textSWIFTBankCode)}
          </Typography>

          {loadingBankList ? (
            <LoadingState />
          ) : (
            <div className={classes.divActionSwift}>
              <AutoCompleteSelect
                options={searchList}
                onChange={handleChangeBank}
                value={searchValue}
                name={"bankSwift"}
                label={""}
                placeholder={t(strings.textSearchBank)}
              />

              {showTableBank()}
            </div>
          )}
        </div>
      </div>
      <div className={classes.divFooter}>
        <Typography className={classes.styleCopyRight}>
          {t(strings.textCopyright)}
        </Typography>
      </div>
    </Grid>
  );
};

export default SwiftCodePage;
