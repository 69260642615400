import { Box, Tooltip, Typography } from "@mui/material";
import classes from "../../RegistMerchant.module.css";
import Buttons from "../../../General/Component/Buttons";
import AddCircleIcon from "../../../General/Icon/AddCircleIcon";
import { useEffect, useState } from "react";
import DialogPopUp from "../../../General/Component/DialogPopUp";
import AutocompleteProvider from "./AutocompleteProvider";
import FormCheckBox from "../../../General/Component/FormCheckBox";
import formatNameUnderscore from "../../../General/Helper/formatNameUnderscore";
import CheckBox from "../../../General/Component/CheckBox";
import DeleteIcon from "../../../General/Icon/DeleteIcon";
import IconButtons from "../../../General/Component/IconButtons";

const CardPayment = ({
  t,
  strings,
  cardName,
  cardModeName,
  title,
  subTitle,
  labelSelected,
  data,
  formDataChannel,
  handleChange,
  handleChangeMode,
  handleDisableAddPayment,
  handleDeleteProvider,
  disabled,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState([]);

  useEffect(() => {
    if (data) {
      let formatData = data.map((item) => ({ ...item, checked: false }));
      setCheckedOptions(formatData);
    }
  }, [data]);

  const handleOpenDialogProvider = () => {
    setOpenDialog(true);
  };
  const handleCloseDialogProvider = () => {
    setOpenDialog(false);
  };

  const onSubmitChosenChannel = () => {
    handleCloseDialogProvider();
  };

  const showSelectedProvider = () => {
    const mappedProvider = formDataChannel[cardName];

    const checkedProviders = mappedProvider?.filter(
      (item) => item.provider.checked
    );

    return checkedProviders?.map((item, index) => {
      let checkedModes = item.modes
        .filter((item) => item.checked)
        .map((data) => data.name);
      let modes = checkedModes.length > 0 ? checkedModes : "-";
      return (
        <Box className={classes.boxCard} key={index}>
          <div className={classes.boxCardSection}>
            <div className={classes.divImageLabelChannel}>
              {item?.other?.logoUrl && (
                <div className={classes.borderedImageChannel}>
                  <img
                    src={item?.other?.logoUrl}
                    alt={item.other.acquiring}
                    className={classes.imageChannelCover}
                  />
                </div>
              )}
            </div>
            <div className={classes.divRowSpace}>
              <div className={classes.divAdditionalChannel}>
                <Typography className={classes.textBold600Darker16}>
                  {item?.provider?.name || ""}
                </Typography>
                <Typography className={classes.textRegularDarkGray14}>{`${t(
                  strings.textPaymentOption
                )}: ${modes}`}</Typography>
              </div>
              <div>
                <Tooltip title={t(strings.textDeletePaymentChannel)} arrow>
                  <span>
                    <IconButtons
                      disabled={disabled}
                      isSolid={false}
                      iconVersion={true}
                      icon={<DeleteIcon />}
                      handleDisabledButton={() => false}
                      onSubmitData={() =>
                        handleDeleteProvider(item.provider?.name, cardName)
                      }
                      aria-label={t(strings.textDeletePaymentChannel)}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>
        </Box>
      );
    });
  };

  const showTitle = () => {
    if (cardName.toLowerCase().includes("virtual")) {
      return t(strings.textVirtualAccountProvider);
    } else if (cardName.toLowerCase().includes("debit")) {
      return t(strings.textDebitProvider);
    } else if (cardName.toLowerCase().includes("wallet")) {
      return t(strings.textWalletProvider);
    } else if (cardName.toLowerCase().includes("otc")) {
      return t(strings.textOTCProvider);
    }
  };

  const showSubTitle = () => {
    if (cardName.toLowerCase().includes("virtual")) {
      return t(strings.textSubtitleChooseVAProvider);
    } else if (cardName.toLowerCase().includes("debit")) {
      return t(strings.textSubtitleChooseDebitProvider);
    } else if (cardName.toLowerCase().includes("wallet")) {
      return t(strings.textSubtitleChooseWalletProvider);
    } else if (cardName.toLowerCase().includes("otc")) {
      return t(strings.textSubtitleChooseOTCProvider);
    }
  };

  const additionalContent = (selectedItem) => {
    const refactoredMode = selectedItem?.mode?.map((item) => ({
      name: item,
      label: item,
      checked: false,
    }));

    const selectMode = formDataChannel[cardName]?.find(
      (item) => item.provider.name === selectedItem?.acquiring
    )?.modes;

    let mergedModes = [];
    if (selectMode) {
      mergedModes = refactoredMode
        .map((item) => {
          const matchingMode = selectMode?.find(
            (mode) => mode.name === item.name
          );

          return matchingMode
            ? { ...item, checked: matchingMode.checked }
            : item;
        })
        .concat(
          selectMode?.filter(
            (mode) => !refactoredMode.some((item) => item.name === mode.name)
          )
        );
    } else {
      mergedModes = refactoredMode;
    }

    const isCheckedProvider = formDataChannel[cardName]?.find(
      (item) => item.provider.name === selectedItem?.acquiring
    )?.provider.checked;

    return (
      <div className={classes.divModeProvider}>
        <div className={classes.divInstallment}>
          {mergedModes.length > 0 && isCheckedProvider && (
            <CheckBox
              name={"mode"}
              title={t(strings.textChoosePayment)}
              options={mergedModes}
              columnsPerRow={1}
              additionalName={selectedItem?.acquiring}
              additionalType={cardName}
              handleChange={handleChangeMode}
            />
          )}
        </div>
      </div>
    );
  };

  const contentProvider = (
    <div className={classes.divContentPopUpProvider}>
      <AutocompleteProvider
        t={t}
        strings={strings}
        cardName={cardName}
        options={checkedOptions || []}
        placeholder={t(strings.textSearchProviders)}
        additionalType={cardName}
        handleChange={handleChange}
        handleChangeMode={handleChangeMode}
        formDataChannel={formDataChannel}
      />

      <div className={classes.divLine} />
      <div className={classes.divAllProvider}>
        {checkedOptions.map((data, index) => {
          return (
            <Box className={classes.boxCard} key={index}>
              <div className={classes.boxCardSection}>
                <FormCheckBox
                  label={formatNameUnderscore(data?.acquiring)}
                  icon={
                    data.logoUrl ||
                    "https://res.cloudinary.com/pastisah-id/image/upload/v1731662683/credit-card-fill_qrhnhw.png"
                  }
                  checked={
                    formDataChannel[cardName]?.find(
                      (item) => item.provider.name === data?.acquiring
                    )?.provider.checked || false
                  }
                  onChange={handleChange}
                  name={data?.acquiring || ""}
                  columnsPerRow={1}
                  borderedIcon={true}
                  additionalContent={additionalContent(data)}
                  additionalType={cardName}
                  allItem={data}
                />
              </div>
            </Box>
          );
        })}
      </div>

      <div className={classes.divEndLine}>
        <div>
          <Buttons
            disabled={disabled}
            label={t(strings.textAddPaymentChannel)}
            handleDisabledButton={() => handleDisableAddPayment(cardName)}
            onSubmitData={onSubmitChosenChannel}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <DialogPopUp
        open={openDialog}
        maxWidth={"sm"}
        onClose={handleCloseDialogProvider}
        title={showTitle()}
        subTitle={showSubTitle()}
        content={contentProvider}
        actions={null}
      />

      <Box className={classes.boxCard}>
        <div className={classes.boxCardSection}>
          <div className={classes.boxCardContent}>
            <div className={classes.divRowList}>
              <Typography className={classes.textBold600Darker16}>
                {title}
              </Typography>
              <Typography className={classes.textGray700Italic12}>
                {subTitle}
              </Typography>
            </div>
            <div>
              <Buttons
                label={t(strings.textChooseProvider)}
                handleDisabledButton={() => {
                  return disabled || false;
                }}
                onSubmitData={handleOpenDialogProvider}
                isSolid={false}
                leftIcon={<AddCircleIcon />}
              />
            </div>

            {formDataChannel[cardName]?.length > 0 && (
              <Typography className={classes.textBold600Darker12}>
                {labelSelected}:
              </Typography>
            )}
            {showSelectedProvider()}
          </div>
        </div>
      </Box>
    </div>
  );
};

export default CardPayment;
