import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import classes from "../RegistMerchant.module.css";
import ExpandMoreIcon from "../../General/Icon/ExpandMoreIcon";
import string from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import { useTranslation } from "react-i18next";
import Buttons from "../../General/Component/Buttons";
import LeftArrowIcon from "../../General/Icon/LeftArrowIcon";
import RightIcon from "../../General/Icon/RightIcon";
import React, { useEffect, useState } from "react";
import getMerchantPreview from "../Helper/getMerchantPreview";
import DialogPopUp from "../../General/Component/DialogPopUp";
import { LoadingState } from "../../General/Component/LoadingComp";
import FormCheckBox from "../../General/Component/FormCheckBox";
import DocumentIcon from "../../General/Icon/DocumentIcon";
import InfoComponent from "../../General/Component/InfoComponent";
import onSubmitMerchant from "../Helper/onSubmitMerchant";
import useSnackbar from "../../General/Helper/useSnackbar";
import SnackbarNotification from "../../General/Component/SnackbarNotification";
import ElementToPrint, { downloadReceipt } from "./ElementToPrint";
import getBucketStorageGcp from "../Helper/getBucketStorageGcp";
import { useDispatch, useSelector } from "react-redux";
import merchantStatusVerification from "../Helper/merchantStatusVerification";
import {
  fetchMerchantVerification,
  fetchUserProfile,
} from "../../Store/userSlice";

const AccordionStyle = styled((props) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ _theme }) => ({
  "& .MuiAccordion-root": {
    backgroundColor: "#fff",
    position: "none",
  },
  "& .MuiAccordionSummary-root": {
    backgroundColor: "#F6F4F5",
  },
  "& .MuiAccordionDetails-root": {
    padding: "10px 0 10px 0",
  },
}));

const AccordionPreview = ({
  setActiveSectionNumber,
  setActiveStepOutlined,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const dispatch = useDispatch();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [openPdfContent, setOpenPdfContent] = useState(false);
  const [logo, setLogo] = useState("");
  const [pdfContent, setPdfContent] = useState({
    page1: [],
    page2: [],
    page3: [],
    page4: [],
  });
  const merchantVerif = useSelector(
    (state) => state.user_data.verificationStatus
  );

  const showPreviewData = (item) => {
    return item?.details?.map((el, index) => (
      <div key={index} className={classes.section_preview}>
        {el.subtitle && (
          <Typography className={classes.subtitle_detail_preview}>
            {el.subtitle}
          </Typography>
        )}
        {el.subtext && (
          <Typography className={classes.subtext_detail_preview}>
            {el.subtext}
          </Typography>
        )}
        <div className={classes.section_value}>
          {el.list?.map((e, eindex) => (
            <React.Fragment key={eindex}>
              {e.type === "img" ? (
                <div className={classes.div_pannel_4}>
                  <div className={classes.div_img_value}>
                    <div className={classes.boxChannelLogo}>
                      <div className={classes.divInsideChannelLogo}>
                        <img src={e.logo} alt="logo" width="40px" />
                      </div>
                    </div>

                    <div>
                      <Typography className={classes.value_payment}>
                        {e.value || "-"}
                      </Typography>
                      <Typography className={classes.value_opsi_pembayaran}>
                        {t(string.textPaymentOption)}: {e.opsiPembayaran || "-"}
                      </Typography>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.div_label_value}>
                  <div className={classes.div_label_opti}>
                    {e.label && (
                      <Typography className={classes.label_preview}>
                        {e.label}
                      </Typography>
                    )}
                    {!e.mandatory && (
                      <Typography className={classes.text_opti_label}>
                        {t(string.textOptional)}
                      </Typography>
                    )}
                  </div>

                  {e.type === "file" ? (
                    <div className={classes.value_file_field}>
                      <DocumentIcon />
                      <Typography className={classes.value_file_text}>
                        {e.value || "-"}
                      </Typography>
                    </div>
                  ) : (
                    <TextField
                      disabled
                      fullWidth
                      defaultValue={e.value || "-"}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-disabled": {
                          "& fieldset": {
                            borderRadius: "8px",
                            border: "1px solid #CFDFEC",
                          },
                        },
                        "& .MuiOutlinedInput-input.Mui-disabled": {
                          borderRadius: "8px",
                          height: "10px",
                          WebkitTextFillColor: "#231F20",
                          background: "#F6F4F5",
                        },
                      }}
                    />
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    ));
  };

  const handleCheck = (check) => {
    setChecked(check);
  };

  const handleDisabledButton = () => {
    return !checked;
  };

  useEffect(() => {
    const mediaId = data[0]?.details[0]?.list[7].value;
    if (mediaId) {
      getBucketStorageGcp(mediaId, setLogo);
    }
  }, [data]);

  useEffect(() => {
    getMerchantPreview(
      t,
      string,
      setData,
      setLoading,
      setPdfContent,
      userDataLogin
    );
    dispatch(fetchMerchantVerification());
  }, [t, userDataLogin]);

  const handlePreviousSection = () => {
    setActiveSectionNumber((prev) => prev - 1);
  };

  const onSubmitContinue = () => {
    if (
      merchantVerif?.staging === "DONE" &&
      merchantVerif?.production === "RESUBMIT"
    ) {
      // ngetestnya jangan diroute preview
      // ngehit update RESUBMIT REQUEST
      const status = "RESUBMIT REQUEST";
      merchantStatusVerification(
        status,
        showSnackbar,
        t,
        string,
        dispatch,
        fetchUserProfile,
        setActiveStepOutlined,
        setActiveSectionNumber
      );
    } else {
      onSubmitMerchant(
        setActiveStepOutlined,
        setActiveSectionNumber,
        setLoadingSubmit,
        showSnackbar,
        t,
        string
      );
    }
  };

  const onSubmitPreview = () => {
    setOpenConfirm(true);
  };

  return !loading ? (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />
      <DialogPopUp
        open={openConfirm}
        maxWidth={"sm"}
        onClose={() => setOpenConfirm(false)}
        title={t(string.textWantContinueProcess)}
        content={
          <>
            <Typography className={classes.content_confirm}>
              {t(string.textMakeSureCorrect)}
            </Typography>
            <div className={classes.divGroupButton}>
              <Buttons
                loadingSubmit={false}
                label={t(string.textNoCheckAgain)}
                handleDisabledButton={() => false}
                onSubmitData={() => setOpenConfirm(false)}
                leftIcon={null}
                isSolid={false}
              />
              <Buttons
                loadingSubmit={loadingSubmit}
                label={t(string.textYesContinue)}
                handleDisabledButton={() => false}
                onSubmitData={onSubmitContinue}
                rightIcon={null}
              />
            </div>
          </>
        }
        actions={null}
      />
      <div>
        <DialogPopUp
          open={openPdfContent}
          maxWidth={"sm"}
          title={t(string.textPreviewData)}
          onClose={() => setOpenPdfContent(false)}
          content={
            <ElementToPrint logo={logo} data={data} pdfContent={pdfContent} />
          }
          actions={
            <div>
              <Buttons
                loadingSubmit={false}
                label={t(string.textExportDataToPdf)}
                handleDisabledButton={() => false}
                onSubmitData={downloadReceipt}
                leftIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M17.5 11.25V16.25C17.5 16.4158 17.4342 16.5747 17.3169 16.6919C17.1997 16.8092 17.0408 16.875 16.875 16.875H3.125C2.95924 16.875 2.80027 16.8092 2.68306 16.6919C2.56585 16.5747 2.5 16.4158 2.5 16.25V11.25C2.5 11.0842 2.56585 10.9253 2.68306 10.8081C2.80027 10.6908 2.95924 10.625 3.125 10.625C3.29076 10.625 3.44973 10.6908 3.56694 10.8081C3.68415 10.9253 3.75 11.0842 3.75 11.25V15.625H16.25V11.25C16.25 11.0842 16.3158 10.9253 16.4331 10.8081C16.5503 10.6908 16.7092 10.625 16.875 10.625C17.0408 10.625 17.1997 10.6908 17.3169 10.8081C17.4342 10.9253 17.5 11.0842 17.5 11.25ZM9.55781 11.6922C9.61586 11.7503 9.68479 11.7964 9.76066 11.8279C9.83654 11.8593 9.91787 11.8755 10 11.8755C10.0821 11.8755 10.1635 11.8593 10.2393 11.8279C10.3152 11.7964 10.3841 11.7503 10.4422 11.6922L13.5672 8.56719C13.6253 8.50912 13.6713 8.44018 13.7027 8.36431C13.7342 8.28844 13.7503 8.20712 13.7503 8.125C13.7503 8.04288 13.7342 7.96156 13.7027 7.88569C13.6713 7.80982 13.6253 7.74088 13.5672 7.68281C13.5091 7.62474 13.4402 7.57868 13.3643 7.54725C13.2884 7.51583 13.2071 7.49965 13.125 7.49965C13.0429 7.49965 12.9616 7.51583 12.8857 7.54725C12.8098 7.57868 12.7409 7.62474 12.6828 7.68281L10.625 9.74141V2.5C10.625 2.33424 10.5592 2.17527 10.4419 2.05806C10.3247 1.94085 10.1658 1.875 10 1.875C9.83424 1.875 9.67527 1.94085 9.55806 2.05806C9.44085 2.17527 9.375 2.33424 9.375 2.5V9.74141L7.31719 7.68281C7.19991 7.56554 7.04085 7.49965 6.875 7.49965C6.70915 7.49965 6.55009 7.56554 6.43281 7.68281C6.31554 7.80009 6.24965 7.95915 6.24965 8.125C6.24965 8.29085 6.31554 8.44991 6.43281 8.56719L9.55781 11.6922Z"
                      fill="#675B5E"
                    />
                  </svg>
                }
                isSolid={true}
              />
            </div>
          }
        />
      </div>

      <div className={classes.divFormStepper}>
        <Typography className={classes.textPreviewData}>
          {t(string.textPreviewData)}
        </Typography>

        <InfoComponent
          severity={"info"}
          message={t(string.textPleaseDoubleCheck)}
        />
        <div className={classes.divForExport}>
          <div style={{ width: "209px" }}>
            <Buttons
              loadingSubmit={false}
              label={"Ekspor data ke pdf"}
              handleDisabledButton={() => false}
              onSubmitData={() => setOpenPdfContent(true)}
              leftIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M17.5 11.25V16.25C17.5 16.4158 17.4342 16.5747 17.3169 16.6919C17.1997 16.8092 17.0408 16.875 16.875 16.875H3.125C2.95924 16.875 2.80027 16.8092 2.68306 16.6919C2.56585 16.5747 2.5 16.4158 2.5 16.25V11.25C2.5 11.0842 2.56585 10.9253 2.68306 10.8081C2.80027 10.6908 2.95924 10.625 3.125 10.625C3.29076 10.625 3.44973 10.6908 3.56694 10.8081C3.68415 10.9253 3.75 11.0842 3.75 11.25V15.625H16.25V11.25C16.25 11.0842 16.3158 10.9253 16.4331 10.8081C16.5503 10.6908 16.7092 10.625 16.875 10.625C17.0408 10.625 17.1997 10.6908 17.3169 10.8081C17.4342 10.9253 17.5 11.0842 17.5 11.25ZM9.55781 11.6922C9.61586 11.7503 9.68479 11.7964 9.76066 11.8279C9.83654 11.8593 9.91787 11.8755 10 11.8755C10.0821 11.8755 10.1635 11.8593 10.2393 11.8279C10.3152 11.7964 10.3841 11.7503 10.4422 11.6922L13.5672 8.56719C13.6253 8.50912 13.6713 8.44018 13.7027 8.36431C13.7342 8.28844 13.7503 8.20712 13.7503 8.125C13.7503 8.04288 13.7342 7.96156 13.7027 7.88569C13.6713 7.80982 13.6253 7.74088 13.5672 7.68281C13.5091 7.62474 13.4402 7.57868 13.3643 7.54725C13.2884 7.51583 13.2071 7.49965 13.125 7.49965C13.0429 7.49965 12.9616 7.51583 12.8857 7.54725C12.8098 7.57868 12.7409 7.62474 12.6828 7.68281L10.625 9.74141V2.5C10.625 2.33424 10.5592 2.17527 10.4419 2.05806C10.3247 1.94085 10.1658 1.875 10 1.875C9.83424 1.875 9.67527 1.94085 9.55806 2.05806C9.44085 2.17527 9.375 2.33424 9.375 2.5V9.74141L7.31719 7.68281C7.19991 7.56554 7.04085 7.49965 6.875 7.49965C6.70915 7.49965 6.55009 7.56554 6.43281 7.68281C6.31554 7.80009 6.24965 7.95915 6.24965 8.125C6.24965 8.29085 6.31554 8.44991 6.43281 8.56719L9.55781 11.6922Z"
                    fill="#675B5E"
                  />
                </svg>
              }
              isSolid={false}
            />
          </div>
        </div>

        <div>
          {data?.map((item, index) => (
            <AccordionStyle key={index} defaultExpanded={item.expanded}>
              <AccordionSummary
                className={classes.Accor}
                expandIcon={<ExpandMoreIcon />}
                id={item.pannel}
              >
                <Typography className={classes.summary_text}>
                  {item.summary}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {showPreviewData(item)}
                <div className={classes.line_bottom} />
              </AccordionDetails>
            </AccordionStyle>
          ))}
          <div className={classes.div_check_confirm}>
            <FormCheckBox
              label={t(string.textLabelConfirmationTnc)}
              checked={checked}
              onChange={handleCheck}
              columnsPerRow={1}
              name={"agreeTNC"}
            />
          </div>

          <div className={classes.divGroupButton}>
            <Buttons
              loadingSubmit={false}
              label={t(string.textPrevious)}
              handleDisabledButton={() => false}
              onSubmitData={handlePreviousSection}
              leftIcon={<LeftArrowIcon />}
              isSolid={false}
            />
            <Buttons
              loadingSubmit={false}
              label={t(string.textNext)}
              handleDisabledButton={handleDisabledButton}
              onSubmitData={onSubmitPreview}
              rightIcon={<RightIcon />}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <LoadingState />
  );
};

export default AccordionPreview;
