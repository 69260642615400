import { Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import HeaderComponent from "../../General/Component/HeaderComponent";
import PopOverComponent from "../../General/Component/PopOverComponent";
import Steppers from "../../General/Component/Steppers";
import getInitials from "../../General/Helper/getInitials";
import ExpandMoreIcon from "../../General/Icon/ExpandMoreIcon";
import ExpandLessIcon from "../../General/Icon/ExpandLessIcon";
import LogoutIcon from "../../General/Icon/LogoutIcon";
import * as routesEnum from "../../General/Constant/routes";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import classes from "../RegistMerchant.module.css";
import NavbarStepper from "../Component/NavbarStepper";
import StepsController from "../Component/StepsController";
import listOutlinedStep from "../Constant/listOutlinedStep";
import listStepOne from "../Constant/listStepOne";
import getProgressData from "../Helper/getProgressData";

const RegistrationForm = () => {
  const { t } = useTranslation("RegistMerchant");
  const navigate = useNavigate();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const [forBtnBack, setForBtnBack] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNavbar, setOpenNavbar] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [activeStepOutlined, setActiveStepOutlined] = useState(0);
  const [activeSectionNumber, setActiveSectionNumber] = useState(0);
  const [errorVerificationData, setErrorVerificationData] = useState([]);
  const [progressData, setProgressData] = useState({
    section1: 0,
    section2: 0,
    section3: 0,
    section4: 0,
    step2: 0,
  });

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (!userDataLogin) return;

    const { onboardingSteps } = userDataLogin;

    const stepConfig = [
      {
        step: "step1",
        sections: [
          "step1Section1",
          "step1Section2",
          "step1Section3",
          "step1Section4",
        ],
      },
      { step: "step2", sections: [] },
      { step: "step3", sections: [] },
      { step: "step4", sections: [] },
      { step: "step5", sections: [] },
    ];

    for (let i = 0; i < stepConfig.length; i++) {
      const { step, sections } = stepConfig[i];

      if (!onboardingSteps[step]) {
        setActiveStepOutlined(i);

        if (sections.length) {
          const incompleteSectionIndex = sections.findIndex(
            (section) => !onboardingSteps[section]
          );
          setActiveSectionNumber(
            incompleteSectionIndex !== -1 ? incompleteSectionIndex : 0
          );
        } else {
          setActiveSectionNumber(0);
        }
        break;
      }
    }

    getProgressData(setProgressData);
  }, [userDataLogin]);

  const handleLogOut = () => {
    localStorage.clear();
    handleClose();
    navigate(routesEnum.LOGIN);
    setSelectedData("logOut");
  };

  const listItemUser = [
    {
      id: "logOut",
      label: t(strings.textLogout),
      icon: <LogoutIcon />,
      type: "error",
      handleItemButton: handleLogOut,
    },
  ];

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const userActionLogin = (
    <Button
      className={classes.buttonUserName}
      aria-describedby={id}
      onClick={handleClick}
      disableRipple
    >
      <div className={classes.divProfile}>
        {getInitials(userDataLogin?.fullname)}
        <div className={classes.divName}>
          <Typography className={classes.textBlackSemiBold14}>
            {userDataLogin?.fullname}
          </Typography>
        </div>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
    </Button>
  );

  const handleOpenNavbar = () => {
    setOpenNavbar(!openNavbar);
  };

  return (
    <>
      <PopOverComponent
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        listItems={listItemUser}
        selectedData={selectedData}
      />

      <HeaderComponent
        userAction={userActionLogin}
        isNavbar={true}
        handleOpenNavbar={handleOpenNavbar}
      />

      <div className={classes.divContainer}>
        <div className={classes.divContainerForm}>
          <NavbarStepper
            handleOpenNavbar={handleOpenNavbar}
            openNavbar={openNavbar}
            listOutlinedStep={listOutlinedStep(t, strings)}
            activeStepOutlined={activeStepOutlined}
            progressData={progressData}
          />

          <div className={classes.divRightStep}>
            <div className={classes.divStepperRight}>
              {activeStepOutlined === 0 && (
                <div className={classes.divStepperHorizontal}>
                  <Steppers
                    activeStep={activeSectionNumber}
                    stepList={listStepOne(t, strings)}
                    orientationStepper="horizontal"
                    dots={false}
                  />
                </div>
              )}
              <div className={classes.divStepperHorizontal}>
                <StepsController
                  activeSectionNumber={activeSectionNumber}
                  setActiveSectionNumber={setActiveSectionNumber}
                  activeStepOutlined={activeStepOutlined}
                  setActiveStepOutlined={setActiveStepOutlined}
                  progressData={progressData}
                  setProgressData={setProgressData}
                  errorVerificationData={errorVerificationData}
                  setErrorVerificationData={setErrorVerificationData}
                  forBtnBack={forBtnBack}
                  setForBtnBack={setForBtnBack}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;
