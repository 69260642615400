import headerRequest from "../../General/Helper/headerRequest";
import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";

const getListChannel = (
  setLoading,
  showSnackbar,
  t,
  strings,
  setChannel,
  setStatusProd,
  setErrorVerificationData
) => {
  const headers = headerRequest();
  setLoading(true);
  RequestHttpGateway.get(`/merchant/verification-status?detail=true`, {
    headers,
  })
    .then((res) => {
      const prod = res.data.data.production;
      const reason = res.data.data.reason;
      setErrorVerificationData(reason);
      setStatusProd(prod);
      setLoading(false);
      const list = res.data.data.requestChannel;
      let cc = [];
      let virtualAccount = [];
      let eWallet = [];
      let otc = [];
      if (list?.CREDIT) {
        cc = list?.CREDIT;
      }
      if (list?.["VIRTUAL ACCOUNT"]) {
        virtualAccount = list?.["VIRTUAL ACCOUNT"];
      }
      if (list?.WALLET) {
        eWallet = list?.WALLET;
      }
      if (list?.OTC) {
        otc = list?.OTC;
      }
      setChannel({
        cc,
        virtualAccount,
        eWallet,
        otc,
      });
    })
    .catch((err) => {
      setLoading(false);
      showSnackbar(
        err.response.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    });
};

export default getListChannel;
