const listIntegrationService = [
  {
    name: "Api Payment Gateway",
    label: "API Direct Integration",
    checked: true,
    disabled: false,
  },

  {
    name: "Api Payment Page",
    label: "Api Payment Page",
    checked: false,
    disabled: true,
  },

  // { name: "Api Payment Gateway", label: "Api Payment Gateway", checked: false },
  // { name: "Api Payment Page", label: "Api Payment Page", checked: false },
  // { name: "Instapay Dashboard", label: "Instapay Dashboard", checked: false },
  // { name: "Email Invoice", label: "Email Invoice", checked: false },
];

export default listIntegrationService;
