import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import classes from "../Style/general.module.css";
import DialogFormPopUp from "./DialogFormPopUp";
import MailIcon from "../Icon/MailIcon";
import MessageIcon from "../Icon/MessageIcon";
import strings from "../Constant/Locales/Strings/RegistMerchantStrings";

const ContactButton = ({ icon, label, onClick }) => (
  <Button className={classes.contactButton} startIcon={icon} onClick={onClick}>
    <Typography className={classes.contactButtonText}>{label}</Typography>
  </Button>
);

const ContactSection = ({ t, title, subtitle }) => {
  const [openContactUs, setOpenContactUs] = useState(false);

  const handleOpenEmailContact = () => {
    setOpenContactUs(true);
  };
  const handleCloseEmailContact = () => {
    setOpenContactUs(false);
  };

  const handleClickOpenWaContact = () => {
    window.open(process.env.REACT_APP_WHATSAPP_URL, "_blank"); // Ganti dengan URL yang diinginkan
  };

  return (
    <>
      <DialogFormPopUp
        open={openContactUs}
        onClose={handleCloseEmailContact}
        maxWidth={"xs"}
      />
      <Box width={"100%"}>
        <Box className={classes.contactContainer}>
          <Box className={classes.contactTextContainer}>
            <Typography
              variant="h6"
              align="center"
              className={classes.contactTitle}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              align="center"
              className={classes.contactSubtitle}
            >
              {subtitle}
            </Typography>
          </Box>

          <Box className={classes.contactButtons}>
            <ContactButton
              icon={<MessageIcon />}
              label={t(strings.sendWhatsAppMessage)}
              onClick={handleClickOpenWaContact}
            />
            <ContactButton
              icon={<MailIcon />}
              label={t(strings.sendEmailMessage)}
              onClick={handleOpenEmailContact}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContactSection;
