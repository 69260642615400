import React, { useState } from "react";
import { Autocomplete, Box, Typography } from "@mui/material";
import { TextField } from "@mui/material";
import classes from "../Style/general.module.css";
import strings from "../Constant/Locales/Strings/GeneralStrings";
import { useTranslation } from "react-i18next";

const AutoCompleteSelect = ({
  options,
  value,
  name,
  label,
  onChange,
  isOptional,
  placeholder,
  getOptionLabel = (option) => option.label,
  getOptionValue = (option) => option.id,
  fullWidth = true,
  isAny = false,
}) => {
  const { t } = useTranslation("General");
  const [inputValue, setInputValue] = useState("");

  return (
    <div className={classes.divFormField}>
      <div className={classes.divTypoField}>
        <Typography className={classes.textBold600BlackH4}>
          {label}
          {isOptional ? (
            <span
              className={classes.textGrayItalic12}
              style={{ margin: "0 0 0 4px" }}
            >
              {t(strings.textOptional)}
            </span>
          ) : isAny ? (
            <span
              className={classes.textGrayItalic12}
              style={{ margin: "0 0 0 4px" }}
            >
              {t(strings.textIsAny)}
            </span>
          ) : null}
        </Typography>
      </div>
      <Autocomplete
        fullWidth={fullWidth}
        displayempty="true"
        options={options}
        name={name || ""}
        getOptionLabel={getOptionLabel}
        value={
          options?.find((option) => getOptionValue(option) === value) || null
        }
        onChange={(event, newValue) => {
          onChange(newValue ? getOptionValue(newValue) : "");
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        inputValue={inputValue}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder || ""}
            fullWidth={fullWidth}
            InputProps={{
              ...params.InputProps,
              sx: {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
            }}
          />
        )}
        renderOption={(props, option) => {
          const { key, ...restProps } = props;
          return (
            <Box
              key={option.id}
              component="li"
              sx={{ display: "flex", alignItems: "center", gap: 2 }}
              {...restProps}
            >
              {option.description ? (
                <div>
                  <Typography className={classes.textBold14}>
                    {option.label}
                  </Typography>
                  <Typography className={classes.textRegularDarkGray14}>
                    {option.description}
                  </Typography>
                  <Typography className={classes.textGray700Italic12}>
                    {option.note}
                  </Typography>
                </div>
              ) : (
                <Typography>{option.label}</Typography>
              )}
            </Box>
          );
        }}
        filterOptions={(options, { inputValue }) => {
          if (
            name?.toLowerCase().includes("referral") &&
            inputValue.length < 3
          ) {
            return [];
          } else {
            return options?.filter((option) =>
              getOptionLabel(option)
                ?.toLowerCase()
                .includes(inputValue.toLowerCase())
            );
          }
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "1px solid #cfdfec",
              display: "flex",
              padding: "14px 12px",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              borderRadius: "8px",
              fontSize: "14px",
            },
          },
        }}
      />
    </div>
  );
};

export default AutoCompleteSelect;
