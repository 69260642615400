import { useTranslation } from "react-i18next";
import Buttons from "../../General/Component/Buttons";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import LeftArrow from "../../General/Icon/LeftArrow";
import classes from "../RegistMerchant.module.css";
import ContactSection from "../../General/Component/ContactSection";
import { CardInformation } from "../../General/Component/CardInformation";

import getVerificationStatus from "../Helper/getVerificationStatus";
import { useEffect, useState } from "react";
import useSnackbar from "../../General/Helper/useSnackbar";
import SnackbarNotification from "../../General/Component/SnackbarNotification";

const VerificationProcess = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
  forBtnBack,
  setForBtnBack,
}) => {
  const { t } = useTranslation("RegistMerchant");
  const detailParam = true;
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const [, setLoading] = useState(false);
  const [, setIsStaging] = useState(false);
  const [, setStatusStaging] = useState(null);
  const [, setIsProd] = useState(false);
  const [, setStatusProd] = useState(null);

  const handlePreviousSection = () => {
    setActiveStepOutlined((prev) => prev - 1);
    setActiveSectionNumber(0);
    setForBtnBack(true);
  };

  const onSubmitVerificationProcess = () => {
    getVerificationStatus(
      setLoading,
      setIsStaging,
      setActiveStepOutlined,
      setActiveSectionNumber,
      t,
      strings,
      showSnackbar,
      setStatusStaging,
      detailParam,
      setIsProd,
      setStatusProd,
      forBtnBack
    );
  };

  useEffect(() => {
    // for handle button back
    setForBtnBack(false);
  }, [setForBtnBack]);

  return (
    <>
      <div className={classes.divFormStepper}>
        <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />
        <CardInformation
          header={t(strings.textDataVerificationInProgress)}
          text={t(strings.textDataVerificationInProgressSubtitle)}
          status={"waiting"}
        />
        <div className={classes.divButtonVerif}>
          <Buttons
            loadingSubmit={false}
            label={t(strings.textCheckVerificationProcess)}
            handleDisabledButton={() => {
              return false;
            }}
            onSubmitData={onSubmitVerificationProcess}
          />
        </div>

        <ContactSection
          t={t}
          title={t(strings.textHaveAQuestion)}
          subtitle={t(strings.textContactViaWaandEmail)}
        />

        <div className={classes.divGroupButton}>
          <Buttons
            loadingSubmit={false}
            label={t(strings.textPrevious)}
            handleDisabledButton={() => false}
            onSubmitData={handlePreviousSection}
            leftIcon={<LeftArrow />}
            isSolid={false}
          />
        </div>
      </div>
    </>
  );
};

export default VerificationProcess;
