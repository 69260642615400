import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const getMROList = (
  t,
  strings,
  setMROList,
  setLoadingMerchant,
  showSnackbar
) => {
  setLoadingMerchant(true);
  const headers = headerRequest();
  RequestHttpGateway.get(`mis/mro`, {
    headers,
  })
    .then((res) => {
      let result = res.data.data;
      let objectResult = result.map((data) => {
        return {
          keyMRO: data.id,
          label: data.mro_name,
          id: data.mro_username,
        };
      });
      setMROList(objectResult);
    })
    .catch((err) => {
      showSnackbar(
        err.response.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    })
    .finally(() => {
      setLoadingMerchant(false);
    });
};

export default getMROList;
