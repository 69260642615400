import classes from "../RegistMerchant.module.css";
import AccordionPreview from "../Component/AccordionPreview";

const PreviewData = ({ setActiveSectionNumber, setActiveStepOutlined }) => {
  return (
    <>
      <div className={classes.divFormStepper}>
        <AccordionPreview
          setActiveSectionNumber={setActiveSectionNumber}
          setActiveStepOutlined={setActiveStepOutlined}
        />
      </div>
    </>
  );
};

export default PreviewData;
