import { Box, Typography } from "@mui/material";

import classes from "../../RegistMerchant.module.css";
import CardDefaultIcon from "../../../General/Icon/CardDefaultIcon";
import RadioButtons from "../../../General/Component/RadioButtons";
import CheckBox from "../../../General/Component/CheckBox";

const CardCC = ({
  t,
  strings,
  title,
  subTitle,
  formDataChannel,
  data,
  period,
  handleChange,
  disabled,
}) => {
  return (
    <Box className={classes.boxCard}>
      <div className={classes.boxCardSection}>
        <div className={classes.boxCardIcon}>
          <CardDefaultIcon />
        </div>
        <div className={classes.boxCardContent}>
          <div className={classes.divRowList}>
            <Typography className={classes.textBold600Darker16}>
              {title}
            </Typography>
            <Typography className={classes.textGray700Italic12}>
              {subTitle}
            </Typography>
          </div>
          <div>
            <RadioButtons
              label={`${t(strings.textChoosePayment)}:`}
              name={"creditCard"}
              value={formDataChannel.creditCard}
              onChange={handleChange}
              options={data}
              row={false}
              disabled={disabled}
            />

            <div className={classes.divInstallment}>
              {formDataChannel.creditCard === "installment" && (
                <CheckBox
                  name={"installmentPeriod"}
                  title={t(strings.textChooseInstallmentMethod)}
                  options={period}
                  columnsPerRow={1}
                  handleChange={handleChange}
                  disabled={disabled}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default CardCC;
