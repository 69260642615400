import { Typography } from "@mui/material";
import AutoCompleteSelect from "../../General/Component/AutocompleteSelect";
import Textfields from "../../General/Component/Textfields";
import { Link } from "react-router-dom";
import * as routeEnum from "../../General/Constant/routes";

export const renderObjectBank = (
  t,
  strings,
  classes,
  field,
  index,
  formData,
  handleChange,
  bankList
) => {
  const isIDR = formData.currency === "IDR";
  const isUSD = formData.currency === "USD";
  const activeBank = bankList?.filter((data) => data.is_active === true);
  const bankNameList = activeBank.map((data) => ({
    id: data.bank_name,
    label: data.bank_name,
  }));

  const idrFields = [
    "bankName",
    "bankBranchName",
    "bankAccountName",
    "bankNumber",
  ];
  const usdFields = [
    "bankName",
    "bankSwiftNumber",
    "bankAccountName",
    "bankNumber",
  ];

  if (isIDR && idrFields.includes(field.name)) {
    if (field.name === "bankName") {
      return (
        <div className={classes.divHalf} key={index}>
          <AutoCompleteSelect
            options={bankNameList}
            value={formData[field.name]}
            name={field.name}
            label={field.label}
            key={index}
            onChange={(e) => handleChange(e, field.name)}
            placeholder={field.placeholder}
          />
        </div>
      );
    } else {
      return (
        <div className={classes.divHalf} key={index}>
          <Textfields
            isHideLabel={true}
            placeholder={field.placeholder}
            label={field.label}
            type={field.type}
            name={field.name}
            value={formData[field.name]}
            onChange={handleChange}
            isValidate={true}
            isOptional={false}
          />
        </div>
      );
    }
  } else if (isUSD && usdFields.includes(field.name)) {
    if (field.name === "bankName") {
      return (
        <div className={classes.divHalf} key={index}>
          <AutoCompleteSelect
            options={bankNameList}
            value={formData[field.name]}
            name={field.name}
            label={field.label}
            key={index}
            onChange={(e) => handleChange(e, field.name)}
            placeholder={field.placeholder}
          />
        </div>
      );
    } else {
      return (
        <div className={classes.divHalf} key={index}>
          <Textfields
            isHideLabel={true}
            placeholder={field.placeholder}
            label={field.label}
            type={field.type}
            name={field.name}
            value={formData[field.name]}
            onChange={handleChange}
            isValidate={true}
            isOptional={false}
          />
          {field.name === "bankSwiftNumber" && (
            <span className={classes.divRowLink}>
              <Typography
                component="span"
                className={classes.textGray12Regular}
              >
                {t(strings.textPleaseCheckBankSwiftCode)}
              </Typography>
              <Link
                to={routeEnum.SWIFT_CODE}
                target="_blank"
                className={classes.textYellow12Regular}
                style={{ margin: "0 0 0 4px" }}
              >
                {t(strings.textHere) + "."}
              </Link>{" "}
            </span>
          )}
        </div>
      );
    }
  }
};
