import { Typography } from "@mui/material";
import classes from "../RegistMerchant.module.css";
import { useTranslation } from "react-i18next";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import HeaderPdfContent from "./HeaderPdfContent";
import React from "react";
import CheckBlueIcon from "../../General/Icon/CheckBlueIcon";
import html2pdf from "html2pdf.js";

export const downloadReceipt = () => {
  var element = document.getElementById("pdf-content");
  const opt = {
    margin: 0.7,
    filename: `preview_data.pdf`,
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2, useCORS: true },
    jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
  };
  html2pdf().from(element).set(opt).save();
};

const ElemetToPrint = ({ logo, data }) => {
  const { t } = useTranslation("RegistMerchant");

  const showPreviewData = (item) => {
    return item?.details?.map((el, index) => (
      <div key={index} className={classes.section_preview}>
        {el.subtitle && (
          <Typography className={classes.subtextDetailPdf}>
            {el.subtitle}
          </Typography>
        )}
        {el.subtext && (
          <Typography className={classes.subtextDetailPdf}>
            {el.subtext}
          </Typography>
        )}
        {item.pannel === "pannel5" ? (
          <>
            <div>
              <Typography className={classes.subtextDetailPdf}>
                {t(strings.textSuccessDocUploaded)}:
              </Typography>
              {item.details[0].list.map((item) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className={classes.divForImageValuePdf}>
                      <CheckBlueIcon />
                      <Typography className={classes.value_file_text}>
                        {item.value || "-"}
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <>
            <div className={classes.section_value}>
              {el.list?.map((e, eindex) => (
                <React.Fragment key={eindex}>
                  {e.type === "img" ? (
                    <div className={classes.divPannel4Pdf}>
                      <div className={classes.div_img_value}>
                        <div className={classes.boxChannelLogo}>
                          <div className={classes.divInsideChannelLogo}>
                            <img src={e.logo} alt="logo" width="40px" />
                          </div>
                        </div>
                        <div>
                          <Typography className={classes.value_payment}>
                            {e.value || "-"}
                          </Typography>
                          <Typography className={classes.value_opsi_pembayaran}>
                            {t(strings.textPaymentOption)}:{" "}
                            {e.opsiPembayaran || "-"}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.divLabelValuePreviewPdf}>
                      <div className={classes.div_label_opti}>
                        {e.label && (
                          <Typography className={classes.labelPreviewPdf}>
                            {e.label}
                          </Typography>
                        )}
                      </div>

                      {e.type === "file" ? (
                        <div className={classes.divForImageValuePdf}>
                          <CheckBlueIcon />
                          <Typography className={classes.valueFileForPdf}>
                            {e.value || "-"}
                          </Typography>
                        </div>
                      ) : (
                        <Typography className={classes.valiePreviewPdf}>
                          {e.value || "-"}
                        </Typography>
                      )}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </>
        )}
      </div>
    ));
  };

  return (
    <>
      <div id="pdf-content">
        <HeaderPdfContent />
        <div className={classes.divContentPdf}>
          <div className={classes.divSectionLogoMerchant}>
            <img
              src={`data:image/jpeg;base64,${logo}`}
              height="65px"
              alt="logo-merchant"
            />
            <div className={classes.divMerchantNameValue}>
              <Typography className={classes.labelMerchantNamePdf}>
                {t(strings.textMerchantName)}
              </Typography>
              <Typography className={classes.valueMerchantNamePdf}>
                {data[0].details[0]?.list[0]?.value
                  ? data[0].details[0]?.list[0]?.value
                  : "-"}
              </Typography>
            </div>
          </div>
          {data?.map((item) => (
            <>
              <div className={classes.divSectionContentPdf}>
                <Typography className={classes.summary_text}>
                  {item.summary}
                </Typography>
              </div>
              <div>{showPreviewData(item)}</div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default ElemetToPrint;
