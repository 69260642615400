import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import formatNameUnderscore from "../../General/Helper/formatNameUnderscore";
import headerRequest from "../../General/Helper/headerRequest";

const getCategoryMerchant = (setIndustryType, setLoadingMerchant) => {
  setLoadingMerchant(true);
  const headers = headerRequest();
  RequestHttpGateway.get(`mis/master/merchant/category`, {
    headers,
  })
    .then((res) => {
      let removedRedundantName = [
        ...new Map(
          res.data.data.map((item) => [item.description, item])
        ).values(),
      ];
      let changeFormat = removedRedundantName.map((data) => {
        return {
          id: data.master_code,
          label: formatNameUnderscore(data.description),
          id_master: data.id,
          created_at: data.created_at,
          // description: data.description,
          master_code: data.master_code,
          master_type: data.master_type,
          updated_at: data.updated_at,
          use_for_instapay: data.use_for_instapay,
        };
      });
      setIndustryType(changeFormat);
    })
    .catch((err) => {})
    .finally(() => {
      setLoadingMerchant(false);
    });
};

export default getCategoryMerchant;
