import { Typography } from "@mui/material";
import InfoComponent from "../../General/Component/InfoComponent";

const InfoDataCorrection = ({ errorVerificationData, t, strings, classes }) => {
  return (
    <>
      {errorVerificationData?.length > 0 && (
        <div style={{ width: "100%" }}>
          <InfoComponent
            severity="warning"
            message={
              <>
                <Typography className={classes.textBold600Darker12}>
                  {t(strings.textLabelRepairingData)}:
                </Typography>
                <span>
                  {errorVerificationData?.map((data, index) => (
                    <li key={index} className={classes.textBold600Darker12}>
                      {data}
                    </li>
                  ))}
                </span>
                <Typography className={classes.textBold600Darker12}>
                  {t(strings.textPleaseMakeCorrection)}
                </Typography>
              </>
            }
          />
        </div>
      )}
    </>
  );
};

export default InfoDataCorrection;
