const SearchIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 18}
    height={heightIcon || 19}
    viewBox="0 0 18 19"
    fill="none"
  >
    <circle
      cx="8.80589"
      cy="8.80541"
      r="7.49047"
      stroke="#A0989A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0156 14.4043L16.9523 17.3334"
      stroke="#A0989A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SearchIcon;
