import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const getVerificationStatus = (
  setLoading,
  setIsStaging,
  setActiveStepOutlined,
  setActiveSectionNumber,
  t,
  strings,
  showSnackbar,
  setStatusStaging,
  detailParam,
  setIsProd,
  setStatusProd,
  forBtnBack
) => {
  const headers = headerRequest();
  setLoading(true);
  RequestHttpGateway.get(
    `/merchant/verification-status?detail=${detailParam}`,
    { headers }
  )
    .then((res) => {
      const staging = res.data?.data?.staging;
      const prod = res.data?.data?.production;
      if (staging === null) {
        // back to preview data
        setActiveStepOutlined((prev) => prev - 1);
        setActiveSectionNumber(1);
        setLoading(false);
      } else if (staging === "DONE" && prod !== "DONE") {
        // prod = IN PROGRESS, REJECTED, ON REVIEW, RESUBMIT, RESUBMIT REQUEST, RESUBMIT PROCESS
        if (prod === "REJECTED" || prod === "ON REVIEW") {
          setActiveStepOutlined((prev) => prev + 1);
          setActiveSectionNumber(0);
          setLoading(false);
        }
        setStatusStaging(true);
      } else if (staging === "DONE" && prod === "DONE") {
        setIsProd(true);
        setStatusStaging(true);
        setLoading(false);
        if (forBtnBack === false) {
          setActiveStepOutlined((prev) => prev + 1);
          setActiveSectionNumber(0);
        }
      } else {
        // stg = IN PROGRESS
        setStatusProd(prod);
        setLoading(false);
        setIsStaging(false);
      }
    })
    .catch((err) => {
      setLoading(false);
      showSnackbar(
        err.response.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    });
};

export default getVerificationStatus;
