import { useTranslation } from "react-i18next";
import classes from "../RegistMerchant.module.css";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import { Typography } from "@mui/material";

const HeaderPdfContent = () => {
  const { t } = useTranslation("RegistMerchant");
  return (
    <div className={classes.divHeaderPdfContent}>
      <div className={classes.divFlexHeader}>
        <img
          src="https://res.cloudinary.com/pastisah-id/image/upload/v1699865688/WhatsApp_Image_2023-10-03_at_18.17.21-removebg-preview_fgl2u3.png"
          className={classes.logoForPdfContent}
          alt="logo"
        />
        <div className={classes.divHeaderRight}>
          <Typography className={classes.textMAF}>
            {t(strings.textMAF)}
          </Typography>
          <Typography className={classes.textAddressHeader}>
            Jl. Buncit Raya No.8a, RT.7/RW.3, Kalibata, Kec. Pancoran, Kota
            Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12740
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default HeaderPdfContent;
