import headerRequest from "../../General/Helper/headerRequest";
import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";

const merchantStatusVerification = (
  status,
  showSnackbar,
  t,
  strings,
  dispatch,
  fetchUserProfile,
  setActiveStepOutlined,
  setActiveSectionNumber
) => {
  const headers = headerRequest();
  const body = {
    statusVerification: status,
  };
  RequestHttpGateway.put(`/merchant/merchant-status-verification`, body, {
    headers,
  })
    .then((_res) => {
      if (status === "RESUBMIT REQUEST") {
        setActiveStepOutlined((prev) => prev + 1);
        setActiveSectionNumber(0);
      } else {
        dispatch(fetchUserProfile());
      }
    })
    .catch((err) => {
      showSnackbar(
        err?.response?.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    });
};

export default merchantStatusVerification;
