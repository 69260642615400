import { VerificationStatus } from "../VerificationStatus";
import FormBusinessInfo from "./FormBusinessInfo";
import FormPIC from "./FormPIC";
import FormPaymentInfo from "./FormPaymentInfo";
import FormRegistrationInfo from "./FormRegistrationInfo";
import WebStaging from "./WebStaging";
import FormSupportingDocument from "./FormSupportingDocument";
import VerificationProcess from "../VerificationProcess";
import AccordionPreview from "./AccordionPreview";

const stepsMap = {
  0: [FormRegistrationInfo, FormPIC, FormBusinessInfo, FormPaymentInfo],
  1: [FormSupportingDocument, AccordionPreview],
  2: [WebStaging],
  3: [VerificationProcess],
  4: [VerificationStatus],
};

const StepsController = ({
  activeSectionNumber,
  setActiveSectionNumber,
  activeStepOutlined,
  setActiveStepOutlined,
  progressData,
  setProgressData,
  errorVerificationData,
  setErrorVerificationData,
  forBtnBack,
  setForBtnBack,
}) => {
  const CurrentStep = stepsMap[activeStepOutlined]?.[activeSectionNumber];
  if (CurrentStep) {
    return (
      <CurrentStep
        activeSectionNumber={activeSectionNumber}
        setActiveSectionNumber={setActiveSectionNumber}
        activeStepOutlined={activeStepOutlined}
        setActiveStepOutlined={setActiveStepOutlined}
        progressData={progressData}
        setProgressData={setProgressData}
        errorVerificationData={errorVerificationData}
        setErrorVerificationData={setErrorVerificationData}
        forBtnBack={forBtnBack}
        setForBtnBack={setForBtnBack}
      />
    );
  }

  return null;
};

export default StepsController;
