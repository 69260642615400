const listCategory = (t, strings) => [
  { id: "API Integration", label: "API Integration", disabled: false },
  { id: "Non Integration", label: "Non Integration", disabled: true },
  {
    id: "Disbursement Only",
    label: "Disbursement Only",
    disabled: true,
  },
];

export default listCategory;
