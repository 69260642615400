import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const getChannelType = (setChannelTypeList, setLoadingChannel) => {
  setLoadingChannel(true);
  const headers = headerRequest();
  RequestHttpGateway.get(`mis/channel/types`, {
    headers,
  })
    .then((res) => {
      setChannelTypeList(res.data.data);
    })
    .catch((err) => {
      //   showSnackbar(
      //     err.response.data.response_message ||
      //       t(strings.textServiceNotAvailable),
      //     "error"
      //   );
    })
    .finally(() => {
      setLoadingChannel(false);
    });
};

export default getChannelType;
