const objectBusinessInfo = (t, strings) => [
  {
    name: "merchantCriteria",
    placeholder: t(strings.textPlaceholdertextMerchantCriteria),
    label: t(strings.textMerchantCriteria),
    type: "select",
    isValidate: true,
  },
  {
    name: "productName",
    placeholder: t(strings.textPlaceholderProductName),
    label: t(strings.textProductName),
    type: "text",
    isValidate: true,
  },
  {
    name: "marketTargetName",
    placeholder: t(strings.textPlaceholderMarketTarget),
    label: t(strings.textMarketTarget),
    type: "text",
    isValidate: true,
  },
  {
    name: "industryName",
    placeholder: t(strings.textPlaceholderIndustryName),
    label: t(strings.textIndustryName),
    type: "select",
    isValidate: true,
  },
  {
    name: "companyTaxType",
    placeholder: t(strings.textPlaceholdertextCompanyTaxType),
    label: t(strings.textCompanyTaxType),
    type: "select",
    isValidate: true,
  },
  {
    name: "monthlySalesAmount",
    placeholder: t(strings.textPlaceholderCurrentMonthlySales),
    label: t(strings.textCurrentMonthlySales),
    type: "text",
    isValidate: true,
  },
  {
    name: "averageBillAmount",
    placeholder: t(strings.textPlaceholderAverageBilling),
    label: t(strings.textAverageBilling),
    type: "text",
    isValidate: true,
  },
  {
    name: "monthlySalesTurnoverAmount",
    placeholder: t(strings.textPlaceholderMonthlySalesTurnover),
    label: t(strings.textMonthlySalesTurnover),
    type: "text",
    isValidate: true,
  },
];

export default objectBusinessInfo;
